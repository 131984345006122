@media (min-width: 960px) {
    body {
        .MuiContainer-maxWidthLg {
            max-width: 900px;
        }
    }
}
@media (min-width: 1100px) {
    body {
        .MuiContainer-maxWidthLg {
            max-width: 1060px;
        }
    }
}
@media (min-width: 1280px) {
    body {
        .MuiContainer-maxWidthLg {
            max-width: 1200px;
        }
    }
}
@media (min-width: 1440px) {
    body {
        .MuiContainer-maxWidthLg {
            max-width: 1400px;
        }
    }
}
@media (min-width: 1600px) {
    body {
        .MuiContainer-maxWidthLg {
            max-width: 1500px;
        }
    }
}
@media (min-width: 1750px) {
    body {
        .MuiContainer-maxWidthLg {
            max-width: 1650px;
        }
    }
}
@media only screen and (max-width: 1850px) {
    .db-profile-page {
        .cus-profile-page {
            .cus-profile-inner {
                .profile-sidebar {
                    padding: 30px;
                    .profile-pic {
                        width: 145px;
                        height: 145px;
                    }
                }
            }
        }
    }
    .db-create-venue-seat {
        .field-rep {
            .tables {
                .tables-inner {
                    margin: 100px 0px;
                    .circle-sec {
                        font-size: 14px;
                    }
                    .tables-rep-sec {
                        ul {
                            height: 12em;
                            width: 12em;
                            &.chair-count-2 {
                                @include on-circle($item-count: 2, $circle-size: 12em, $item-size: 4em);
                                > * {
                                    margin: -2em;
                                    width: 4em;
                                    height: 4em;
                                }
                            }
                            &.chair-count-4 {
                                @include on-circle($item-count: 4, $circle-size: 12em, $item-size: 4em);
                                > * {
                                    margin: -2em;
                                    width: 4em;
                                    height: 4em;
                                }
                            }
                            &.chair-count-6 {
                                @include on-circle($item-count: 6, $circle-size: 12em, $item-size: 4em);
                                > * {
                                    margin: -2em;
                                    width: 4em;
                                    height: 4em;
                                }
                            }
                            &.chair-count-8 {
                                @include on-circle($item-count: 8, $circle-size: 12em, $item-size: 4em);
                                > * {
                                    margin: -2em;
                                    width: 4em;
                                    height: 4em;
                                }
                            }
                            &.chair-count-10 {
                                @include on-circle($item-count: 10, $circle-size: 12em, $item-size: 4em);
                                > * {
                                    margin: -2em;
                                    width: 4em;
                                    height: 4em;
                                }
                            }
                            &.chair-count-12 {
                                @include on-circle($item-count: 12, $circle-size: 12em, $item-size: 4em);
                                > * {
                                    margin: -2em;
                                    width: 4em;
                                    height: 4em;
                                }
                            }
                            &.chair-count-14 {
                                @include on-circle($item-count: 14, $circle-size: 12em, $item-size: 4em);
                                > * {
                                    margin: -2em;
                                    width: 4em;
                                    height: 4em;
                                }
                            }
                            &.chair-count-16 {
                                @include on-circle($item-count: 16, $circle-size: 12em, $item-size: 4em);
                                > * {
                                    margin: -2em;
                                    width: 4em;
                                    height: 4em;
                                }
                            }
                            &.chair-count-18 {
                                @include on-circle($item-count: 18, $circle-size: 12em, $item-size: 4em);
                                > * {
                                    margin: -2em;
                                    width: 4em;
                                    height: 4em;
                                }
                            }
                            &.chair-count-20 {
                                @include on-circle($item-count: 20, $circle-size: 12em, $item-size: 4em);
                                > * {
                                    margin: -2em;
                                    width: 4em;
                                    height: 4em;
                                }
                            }
                            > * {
                                &::before {
                                    font-size: 14px !important;
                                }
                            }
                            li {
                                .seat-inner-img {
                                    svg {
                                        width: 30px;
                                        height: 30px;
                                    }
                                    .seat-empty {
                                        width: 30px;
                                        height: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1749px) {
    .cus-main-slider {
        .slider-right-sec {
            .cat-grids-sec {
                ul {
                    li {
                        .overlap-cards-con {
                            .overlap-cards-top-img {
                                height: 150px;
                            }
                            .overlap-cards-bottom-img {
                                width: 205px;
                                height: 210px;
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-auth-page {
        .cus-auth-inner {
            &:after,
            &:before {
                width: 500px;
                height: 500px;
                left: 0;
            }
            &:before {
                left: auto;
                right: 0;
            }
        }
    }
    .cus-profile-page {
        .cus-profile-inner {
            .profile-sidebar {
                padding: 30px;
            }
        }
    }
    .cus-booking-page {
        .cus-booking-inner {
            .select-cat-sec {
                padding: 0 40px;
            }
        }
    }
    .cus-sec-heading {
        h2 {
            font-size: 30px;
        }
    }
    .cus-modal {
        .cus-sec-heading {
            h2 {
                font-size: 26px;
            }
        }
    }
    .db-heading {
        h2 {
            font-size: 28px;
        }
    }
    .db-sub-heading {
        h3 {
            font-size: 20px;
        }
    }
    .db-user-top-grids {
        .das-sum {
            .das-sum-listing {
                .db-sum-card {
                    .db-sum-card-inner {
                        h3 {
                            font-size: 38px;
                        }
                        p {
                            font-size: 18px;
                        }
                    }
                    img {
                        width: 100px;
                        height: 100px;
                    }
                }
            }
        }
    }
    .db-event {
        .das-sum {
            .db-event-listing {
                .db-event-list {
                    .event-list-img {
                        height: 230px;
                    }
                }
            }
        }
    }
    .db-manual-tickets-page {
        .promo-code-inner {
            ul {
                max-width: 70%;
            }
        }
    }
    .cus-show-filters {
        .shows-filters-container {
            .accordion-details {
                .filter-date-picker {
                    .DateRangePicker {
                        .DateInput {
                            width: 85px;
                        }
                        .DateInput_input {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
    .cus-banner {
        .banner-contents {
            h2 {
                font-size: 40px;
            }
        }
    }
    .db-tickets-page {
        .event-actions {
            .cus-button-groups {
                button {
                    &.cus-btn {
                        margin: 0 3px;
                        &.blue-btn {
                            max-width: 100px;
                        }
                    }
                }
            }
        }
    }
    .cus-seat-arrangement {
        .cus-items-zone {
            .cus-seats-items {
                .cus-seats-grid {
                    .cus-seat-plan {
                        .cus-row-name {
                            top: 3px;
                            h3 {
                                font-size: 12px;
                            }
                        }
                        .seat-inner {
                            width: 15px;
                            height: 15px;
                            svg {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1599px) {
    .cus-btn {
        font-size: 14px;
    }
    button {
        &.cus-btn {
            font-size: 14px;
        }
    }
    .cus-header {
        header {
            .header-toolbar {
                .cus-menus {
                    li {
                        padding: 8px 30px;
                    }
                }
            }
        }
    }
    .cus-sec-heading {
        h2 {
            font-size: 28px;
        }
    }
    .cus-modal {
        .cus-sec-heading {
            h2 {
                font-size: 24px;
            }
        }
    }
    .db-heading {
        h2 {
            font-size: 26px;
        }
    }
    .db-sub-heading {
        h3 {
            font-size: 18px;
        }
    }
    .cus-main-slider {
        .slider-left-sec {
            .slider-list {
                .slider-contents {
                    h5 {
                        font-size: 18px;
                    }
                    h2 {
                        font-size: 40px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
        .slider-right-sec {
            .cat-grids-sec {
                ul {
                    li {
                        margin-bottom: 25px;
                        .overlap-cards-con {
                            .overlap-cards-top-img {
                                height: 140px;
                            }
                            .overlap-cards-bottom-img {
                                width: 188px;
                                height: 198px;
                                .overlap-cards-content {
                                    padding: 15px 15px 10px 15px;
                                    .cat-tittle-show {
                                        width: calc(100% - 30px);
                                        h4 {
                                            font-size: 16px;
                                        }
                                        p {
                                            font-size: 13px;
                                        }
                                    }
                                    .cat-arrow {
                                        width: 30px;
                                        height: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .banner-ad-sec {
        .ads-left-con {
            h5 {
                font-size: 16px;
            }
            p {
                font-size: 14px;
            }
        }
    }
    .home-about-sec {
        .about-sec-right {
            p {
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
    .cus-upcoming-shows {
        .upcoming-shows-inner {
            .upcoming-shows-listing {
                .cus-card-inner {
                    .card-contents {
                        h2 {
                            font-size: 18px;
                        }
                    }
                    .card-date {
                        h5,
                        h6 {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .cus-contact-page {
        .cus-contact-us {
            .contact-us-left-sec {
                .contact-us-left-inner {
                    p {
                        font-size: 14px;
                    }
                }
            }
            .contact-us-right-sec {
                .contact-us-right-inner {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .cus-faq-page {
        .cus-faq-con {
            .faq-options {
                ul {
                    li {
                        h3 {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .cus-have-question {
            .cus-sec-sub-heading {
                p {
                    font-size: 16px;
                }
            }
        }
    }
    .cus-accordion {
        .accordion-listing {
            .accordion-summary {
                padding: 15px 0;
                h3 {
                    font-size: 16px;
                }
            }
            .accordion-details {
                p {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }
    .cus-show-filters {
        .shows-filters-container {
            .accordion-listing {
                .accordion-summary {
                    .filter-heading {
                        .filter-head-icon {
                            font-size: 14px;
                        }
                        .clear-filter {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
    .show-detail-page {
        .cus-show-detail-slider {
            .show-detail-slider {
                .detail-slider-con {
                    max-width: 70%;
                    h5 {
                        font-size: 18px;
                    }
                    h2 {
                        font-size: 40px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .single-show-page {
        .single-show-inner {
            .show-description {
                p {
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }
            .date-location {
                ul {
                    li {
                        .date-loc-inner {
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                            h5 {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
        .artist-cards-inner {
            .cus-artist-cards {
                ul {
                    li {
                        width: 140px;
                        margin-right: 30px;
                        .artist-card {
                            .artist-card-img {
                                width: 140px;
                                height: 140px;
                            }
                            .artist-card-con {
                                h3 {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .sell-ticket-page {
        .sell-tickets-listing {
            .tickets-img {
                width: 400px;
                height: 400px;
            }
            .tickets-con {
                p {
                    font-size: 14px;
                }
            }
        }
    }
    .cus-banner {
        .banner-contents {
            h2 {
                font-size: 35px;
            }
            p {
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
    .cus-take-away {
        .take-away-img {
            img {
                width: 120px;
                height: 120px;
            }
        }
        p {
            font-size: 14px;
        }
    }
    .pick-your-venue-page {
        .pick-your-venue-sec {
            .cus-venue-list-sec {
                .venue-listing {
                    .venue-con {
                        p {
                            font-size: 14px;
                            span {
                                min-height: 42px;
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-listing-design {
        ul {
            li {
                h3 {
                    font-size: 18px;
                }
                p {
                    font-size: 14px;
                }
                &:after {
                    width: 18px;
                    height: 18px;
                    top: 15px;
                }
            }
        }
        &.cus-numbering-listing {
            ul {
                li {
                    &:after {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .cus-auth-page {
        .auth-logo {
            margin: 30px 0px;
        }
        .cus-auth-inner {
            min-height: calc(100vh - 145px);
            padding-top: 0px;
            &:after,
            &:before {
                width: 400px;
                height: 400px;
            }
        }
    }
    .cus-help-page {
        .cus-help-inner {
            .cus-help-form {
                .cus-help-form-inner {
                    max-width: 400px;
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .cus-tab-list {
        header {
            button {
                font-size: 14px;
            }
        }
        .tab-contents {
            .cus-profile-fileds {
                .tab-inner-head {
                    h4 {
                        font-size: 18px;
                    }
                }
                ul {
                    li {
                        .left-label {
                            p {
                                font-size: 14px;
                            }
                        }
                        .right-field {
                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-booking-page {
        .cus-booking-inner {
            .booking-head-locations {
                h3 {
                    font-size: 28px;
                }
                h4 {
                    font-size: 16px;
                }
            }
            .select-cat-sec {
                padding: 0;
                h2 {
                    font-size: 22px;
                }
                .seat-category-selection {
                    .header {
                        h5 {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        .cus-booking-accordion {
            .payment-options-sec {
                .payment-left-grid {
                    .payment-options-tab {
                        ul {
                            li {
                                img {
                                    width: 22px;
                                    height: 18px;
                                }
                            }
                        }
                    }
                }
            }
            .contact-detail-sec {
                ul {
                    li {
                        button {
                            &.cus-btn {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .promotional-code-sec {
                ul {
                    li {
                        .promo-field {
                            width: 60%;
                            button {
                                &.cus-btn {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
            .cus-accordion {
                .accordion-listing {
                    .accordion-summary {
                        h3 {
                            font-size: 18px;
                        }
                    }
                    .accordion-details {
                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .booking-summary {
            h3 {
                font-size: 18px;
            }
            ul {
                li {
                    p {
                        font-size: 14px;
                        span {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
    body {
        .cus-modal {
            &.modal-lg {
                .MuiPaper-root {
                    width: 1150px;
                }
            }
            &.modal-md {
                .MuiPaper-root {
                    width: 950px;
                }
            }
            &.modal-sm {
                .MuiPaper-root {
                    width: 700px;
                }
            }
            &.modal-xs {
                .MuiPaper-root {
                    width: 480px;
                }
            }
        }
    }
    .confirm-booking-modal {
        .thanks-buying-ticket {
            h3 {
                font-size: 26px;
            }
        }
    }
    .cus-tab-list {
        .tab-contents {
            .wallet-list {
                ul {
                    li {
                        .wallet-list-inner {
                            .wallet-head {
                                p {
                                    font-size: 18px;
                                }
                            }
                            .available-bal {
                                p {
                                    font-size: 14px;
                                    &.bal-here {
                                        font-size: 22px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .cus-booking-history {
                ul {
                    li {
                        .booking-history-left {
                            .booking-history-top {
                                .history-top-rep {
                                    p {
                                        font-size: 14px;
                                    }
                                }
                            }
                            .booking-history-details {
                                .booking-history-img {
                                    width: 120px;
                                    height: 120px;
                                }
                                .history-details-right {
                                    width: calc(100% - 150px);
                                    .history-heading,
                                    .history-ticket-detail {
                                        p {
                                            font-size: 14px;
                                        }
                                    }
                                    .booking-price-sec {
                                        p {
                                            font-size: 16px;
                                            .price-label-left {
                                                font-size: 14px;
                                            }
                                            .price-label-right {
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .drawer-custom-menu {
        .db-cus-logo {
            img {
                width: 170px;
            }
        }
        .sidebar-menus {
            .sidepanel-item {
                img {
                    width: 20px;
                    height: 20px;
                }
                span {
                    font-size: 14px;
                }
            }
        }
    }
    .db-user-top-grids {
        .db-heading {
            h2 {
                img {
                    width: 26px;
                    height: 26px;
                }
            }
        }
        .das-sum {
            .das-sum-listing {
                .db-sum-card {
                    padding: 30px 15px;
                    .db-sum-card-inner {
                        h3 {
                            font-size: 36px;
                        }
                        p {
                            font-size: 18px;
                        }
                    }
                    img {
                        width: 90px;
                        height: 90px;
                    }
                }
            }
        }
    }
    .db-event {
        .das-sum {
            .db-event-listing {
                .db-event-list {
                    .event-list-img {
                        height: 200px;
                    }
                    .db-event-summary {
                        h2 {
                            font-size: 18px;
                        }
                        p {
                            font-size: 14px;
                        }
                        .db-date-time {
                            ul {
                                li {
                                    span {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .db-bar-events {
        .db-upcoming-events {
            padding: 25px;
            li {
                .upcoming-con {
                    h5 {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .db-section-heading {
        h4 {
            font-size: 18px;
        }
    }
    .container-spacing-50 {
        padding: 30px;
    }
    .db-add-events-page {
        .db-add-event-con {
            .event-checkbox-sec {
                .event-checkbox-list {
                    h6 {
                        font-size: 16px;
                    }
                    label {
                        span {
                            &.MuiFormControlLabel-label {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .cus-right-btn {
                button {
                    &.cus-btn {
                        min-width: 180px;
                    }
                }
            }
        }
    }
    .cus-gift-vouchers {
        .cus-accordion {
            .accordion-listing {
                .accordion-summary {
                    h3 {
                        font-size: 18px;
                    }
                }
            }
        }
        ul {
            li {
                .gift-voucher-list {
                    .gift-voucher-con {
                        p {
                            font-size: 22px;
                        }
                        .gift-voucher-bottom {
                            p {
                                font-size: 15px;
                            }
                            button {
                                &.cus-btn {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .db-events-prices-page {
        .event-card-prices {
            .events-prices-listing {
                span {
                    &.MuiFormControlLabel-label {
                        font-size: 14px;
                    }
                }
                .p-left-padd {
                    font-size: 14px;
                }
            }
            .cus-booking-fee {
                h6 {
                    font-size: 14px;
                }
            }
            .cus-general-prices {
                ul {
                    li {
                        h5 {
                            font-size: 14px;
                        }
                    }
                }
            }
            .cus-right-btn {
                button {
                    &.cus-btn {
                        min-width: 180px;
                    }
                }
            }
        }
    }
    .db-events-performance-page {
        .db-events-per-container {
            .db-events-performance-inner {
                ul {
                    li {
                        h5 {
                            font-size: 14px;
                        }
                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .add-more-performance {
            button {
                &.cus-btn {
                    min-width: 280px;
                }
            }
        }
        .cus-right-btn {
            button {
                &.cus-btn {
                    min-width: 180px;
                }
            }
        }
    }
    .db-web-page {
        .web-page-inner {
            max-width: 80%;
        }
        button {
            &.cus-btn {
                min-width: 160px;
            }
        }
        .dramatix-flyer-btn {
            button {
                &.cus-btn {
                    min-width: 200px;
                }
            }
        }
    }
    .cus-tab-list {
        .tab-contents {
            .cus-profile-fileds {
                .registered-gst {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                    p {
                        font-size: 12px;
                    }
                }
            }
            .tab-inner-head {
                button {
                    &.cus-btn {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .cus-profile-page {
        .cus-profile-inner {
            .profile-sidebar {
                .profile-con {
                    h4 {
                        font-size: 16px;
                    }
                    h6 {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .db-profile-page {
        .cus-profile-page {
            .cus-profile-inner {
                .profile-sidebar {
                    padding: 20px;
                    .profile-pic {
                        width: 125px;
                        height: 125px;
                    }
                }
            }
        }
        .cus-tab-list {
            .tab-contents {
                .cus-booking-history {
                    ul {
                        li {
                            .booking-history-right {
                                .right-links-rep {
                                    a {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .custom-web-page {
        .web-page-logo {
            img {
                max-width: 180px;
            }
        }
        .web-page-contents {
            p {
                font-size: 14px;
            }
        }
    }
    .db-manual-tickets-page {
        .promo-code-inner {
            max-width: 85%;
            .db-sub-heading {
                p {
                    font-size: 14px;
                }
            }
            ul {
                max-width: 60%;
            }
        }
        .create-ticket-btn {
            button {
                &.cus-btn {
                    min-width: 180px;
                }
            }
        }
    }
    .db-manual-tickets-detail {
        .manual-ticket-sec {
            .manual-ticket-btn {
                button {
                    &.cus-btn {
                        min-width: 180px;
                    }
                }
            }
        }
    }
    .db-sub-heading {
        p {
            font-size: 14px;
        }
    }
    .cus-profile-page {
        .cus-profile-inner {
            .profile-sidebar {
                .profile-pic {
                    width: 145px;
                    height: 145px;
                }
            }
        }
    }
    .cus-center-heading {
        h5 {
            font-size: 14px;
        }
    }
    .db-tickets-page {
        .db-heading {
            padding: 0 0 10px 0;
        }
        .tickets-fields {
            ul {
                li {
                    p,
                    h5 {
                        font-size: 14px;
                    }
                }
            }
        }
        .event-actions {
            .cus-button-groups {
                button {
                    &.cus-btn {
                        font-size: 12px;
                        padding: 6px 12px;
                        &.blue-btn {
                            max-width: max-content;
                        }
                    }
                }
            }
        }
    }
    .ad-report-page {
        .cus-bottom-price {
            ul {
                li {
                    h4,
                    h5 {
                        font-size: 18px;
                    }
                    h2,
                    h3 {
                        font-size: 24px;
                    }
                }
            }
        }
    }
    .cus-seat-arrangement {
        .cus-seats-options {
            ul {
                li {
                    font-size: 14px;
                }
            }
        }
    }
    .event-actions {
        .cus-button-groups {
            button {
                &.cus-btn {
                    font-size: 11px;
                    padding: 4px;
                }
            }
        }
    }
    .db-create-venue-seat {
        .field-rep {
            .tables {
                .tables-inner {
                    margin: 80px 0px;
                    .circle-sec {
                        font-size: 12px;
                    }
                    .tables-rep-sec {
                        ul {
                            height: 10em;
                            width: 10em;
                            &.chair-count-2 {
                                @include on-circle($item-count: 2, $circle-size: 10em, $item-size: 2em);
                                > * {
                                    margin: -2em;
                                    width: 4em;
                                    height: 4em;
                                }
                            }
                            &.chair-count-4 {
                                @include on-circle($item-count: 4, $circle-size: 10em, $item-size: 2em);
                                > * {
                                    margin: -1em;
                                    width: 2em;
                                    height: 2em;
                                    padding: 6px;
                                }
                            }
                            &.chair-count-6 {
                                @include on-circle($item-count: 6, $circle-size: 10em, $item-size: 2em);
                                > * {
                                    margin: -1em;
                                    width: 2em;
                                    height: 2em;
                                    padding: 6px;
                                }
                            }
                            &.chair-count-8 {
                                @include on-circle($item-count: 8, $circle-size: 10em, $item-size: 2em);
                                > * {
                                    margin: -1em;
                                    width: 2em;
                                    height: 2em;
                                    padding: 6px;
                                }
                            }
                            &.chair-count-10 {
                                @include on-circle($item-count: 10, $circle-size: 10em, $item-size: 2em);
                                > * {
                                    margin: -1em;
                                    width: 2em;
                                    height: 2em;
                                    padding: 6px;
                                }
                            }
                            &.chair-count-12 {
                                @include on-circle($item-count: 12, $circle-size: 10em, $item-size: 2em);
                                > * {
                                    margin: -1em;
                                    width: 2em;
                                    height: 2em;
                                    padding: 6px;
                                }
                            }
                            &.chair-count-14 {
                                @include on-circle($item-count: 14, $circle-size: 10em, $item-size: 2em);
                                > * {
                                    margin: -1em;
                                    width: 2em;
                                    height: 2em;
                                    padding: 6px;
                                }
                            }
                            &.chair-count-16 {
                                @include on-circle($item-count: 16, $circle-size: 10em, $item-size: 2em);
                                > * {
                                    margin: -1em;
                                    width: 2em;
                                    height: 2em;
                                    padding: 6px;
                                }
                            }
                            &.chair-count-18 {
                                @include on-circle($item-count: 18, $circle-size: 10em, $item-size: 2em);
                                > * {
                                    margin: -1em;
                                    width: 2em;
                                    height: 2em;
                                    padding: 6px;
                                }
                            }
                            &.chair-count-20 {
                                @include on-circle($item-count: 20, $circle-size: 10em, $item-size: 2em);
                                > * {
                                    margin: -1em;
                                    width: 2em;
                                    height: 2em;
                                    padding: 6px;
                                }
                            }
                            > * {
                                &::before {
                                    font-size: 10px !important;
                                }
                            }
                            li {
                                .seat-inner-img {
                                    svg {
                                        width: 16px;
                                        height: 16px;
                                    }
                                    .seat-empty {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .custom-chart-main {
        padding: 25px;
        .custom-chart-heading {
            .chart-head-left {
                h6 {
                    font-size: 18px;
                }
            }
        }
    }
}
@media only screen and (max-width: 1439px) {
    .cus-main-slider {
        .slider-left-sec {
            .slider-list {
                .slider-contents {
                    h5 {
                        font-size: 16px;
                    }
                    h2 {
                        font-size: 35px;
                    }
                }
            }
        }
        .slider-right-sec {
            .cat-grids-sec {
                ul {
                    li {
                        .overlap-cards-con {
                            .overlap-cards-top-img {
                                height: 110px;
                            }
                            .overlap-cards-bottom-img {
                                width: 167px;
                                height: 167px;
                            }
                        }
                    }
                }
            }
        }
    }
    .show-detail-page {
        .cus-show-detail-slider {
            .show-detail-slider {
                .detail-slider-con {
                    h5 {
                        font-size: 16px;
                    }
                    h2 {
                        font-size: 35px;
                    }
                }
            }
        }
    }
    .sell-ticket-page {
        .sell-tickets-listing {
            .tickets-img {
                width: 350px;
                height: 350px;
            }
        }
    }
    .cus-banner {
        .banner-contents {
            max-width: 70%;
            padding: 60px;
            h2 {
                font-size: 30px;
            }
        }
    }
    .cus-take-away {
        .take-away-img {
            img {
                width: 100px;
                height: 100px;
            }
        }
    }
    .cus-booking-page {
        .payment-options-sec {
            .payment-left-grid {
                padding-right: 0;
            }
        }
    }
    .cus-profile-page {
        .cus-profile-inner {
            .profile-sidebar {
                padding: 20px;
                .profile-pic {
                    width: 125px;
                    height: 125px;
                }
            }
        }
    }
    .db-profile-page {
        .cus-profile-page {
            .cus-profile-inner {
                .profile-sidebar {
                    padding: 20px;
                    .profile-pic {
                        width: 110px;
                        height: 110px;
                    }
                }
            }
        }
    }
    .cus-tab-list {
        .tab-contents {
            .tab-inner-head {
                button {
                    &.cus-btn {
                        min-width: 90px;
                        height: 40px;
                    }
                }
            }
            .cus-booking-history {
                ul {
                    li {
                        .booking-history-left {
                            width: calc(100% - 180px);
                        }
                        .booking-history-right {
                            width: 180px;
                        }
                    }
                }
            }
        }
    }
    .cus-gift-vouchers {
        ul {
            li {
                width: 350px;
                .gift-voucher-list {
                    .gift-voucher-con {
                        p {
                            font-size: 20px;
                        }
                        .gift-voucher-bottom {
                            margin-top: 30px;
                            button {
                                &.cus-btn {
                                    min-width: 160px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .db-main-container {
        .db-main-con-inner {
            .db-right-container {
                padding: 0 30px;
            }
        }
    }
    .cus-promoter-events {
        .cus-upcoming-shows {
            .upcoming-shows-inner {
                margin: -8px;
                width: calc(100% + 16px);
                .upcoming-shows-listing {
                    padding: 8px;
                }
            }
        }
    }
    .db-create-promocodes {
        .cus-gift-vouchers {
            ul {
                li {
                    .gift-voucher-list {
                        .gift-voucher-con {
                            .gift-voucher-bottom {
                                margin-top: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    .db-create-venue-seat {
        .del-icon {
            right: -20px;
        }
    }
    .auth-wrapper {
        .cus-form {
            .cus-form-inner {
                .cus-form-width {
                    .auth-logo {
                        margin: 0 0 20px 0;
                        min-height: 80px;
                        img {
                            max-width: 200px;
                        }
                    }
                }
            }
        }
        .split {
            .centered {
                .auth-wrapper-content {
                    h2 {
                        font-size: 20px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .db-tickets-page {
        .event-actions {
            .cus-button-groups {
                button {
                    &.cus-btn {
                        padding: 6px 6px;
                    }
                }
            }
        }
    }
    body {
        .user-dropdown {
            .MuiMenu-paper {
                margin-left: -5px;
            }
        }
    }
}
@media only screen and (max-width: 1279px) {
    .cus-header {
        header {
            .header-toolbar {
                .cus-logo {
                    a {
                        img {
                            height: 60px;
                        }
                    }
                }
                .cus-menus {
                    li {
                        padding: 8px 20px;
                    }
                }
            }
        }
    }
    .cus-main-slider {
        .slider-right-sec {
            .cat-grids-sec {
                ul {
                    li {
                        .overlap-cards-con {
                            .overlap-cards-top-img {
                                height: 90px;
                            }
                            .overlap-cards-bottom-img {
                                width: 145px;
                                height: 145px;
                                .overlap-cards-content {
                                    padding: 15px 12px 10px 12px;
                                    .cat-tittle-show {
                                        h4 {
                                            font-size: 14px;
                                        }
                                        p {
                                            font-size: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .slider-left-sec {
            .slider-list {
                .slider-contents {
                    h2 {
                        font-size: 30px;
                    }
                    h5 {
                        font-size: 14px;
                    }
                    .slider-btns {
                        button {
                            min-width: 160px;
                        }
                    }
                }
            }
            .slick-dots {
                bottom: 15px;
                li {
                    button {
                        &::before {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .cus-footer {
        .footer-middle-sec {
            .footer-social-media {
                padding-left: 0;
            }
        }
    }
    .cus-auth-page {
        overflow: hidden;
        .cus-auth-inner {
            &:after,
            &:before {
                width: 320px;
                height: 320px;
            }
            &::after {
                left: -50px;
            }
            &::before {
                right: -50px;
            }
            .cus-auth-con {
                .forgot-password-sec,
                .resend-otp-sec {
                    a {
                        font-size: 14px;
                    }
                }
                .auth-static-txt,
                .cus-account-txt,
                .agree-con-sec {
                    p {
                        font-size: 14px;
                        line-height: normal;
                    }
                }
            }
        }
    }
    .cus-sec-sub-heading {
        p {
            font-size: 14px;
        }
    }
    .show-detail-page {
        .cus-show-detail-slider {
            .slick-dots {
                bottom: 15px;
            }
            .show-detail-slider {
                .detail-slider-con {
                    padding: 30px 50px;
                    h5 {
                        font-size: 16px;
                    }
                    h2 {
                        font-size: 30px;
                    }
                    .detail-slider-btns {
                        button {
                            min-width: 160px;
                        }
                    }
                }
            }
        }
    }
    .single-show-page {
        .artist-cards-inner {
            .cus-artist-cards {
                ul {
                    li {
                        .artist-card {
                            .artist-card-img {
                                width: 120px;
                                height: 120px;
                            }
                        }
                    }
                }
            }
        }
    }
    .container-spacing {
        padding: 40px;
    }
    .cus-back-arrow {
        img {
            width: 40px;
            height: 20px;
        }
    }
    .cus-booking-page {
        .cus-booking-inner {
            .booking-head-locations {
                h3 {
                    font-size: 26px;
                }
                h4 {
                    font-size: 14px;
                    img {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
    .banner-ad-sec {
        .ads-left-con {
            padding: 60px;
        }
    }
    .cus-banner {
        .banner-contents {
            max-width: 75%;
            padding: 30px 50px;
        }
    }
    .cus-booking-page {
        .cus-booking-accordion {
            .promotional-code-sec {
                ul {
                    li {
                        .promo-field {
                            width: 80%;
                        }
                    }
                }
            }
        }
        .booking-summary {
            padding: 25px 15px;
        }
    }
    .cus-profile-page {
        .cus-profile-inner {
            .profile-sidebar {
                .profile-pic {
                    width: 100px;
                    height: 100px;
                    padding: 30px;
                }
            }
        }
    }
    .db-profile-page {
        .cus-profile-page {
            .cus-profile-inner {
                .profile-sidebar {
                    padding: 15px;
                    .profile-pic {
                        width: 80px;
                        height: 80px;
                        padding: 25px;
                    }
                    .profile-con {
                        h6 {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .cus-tab-list {
            .tab-contents {
                .cus-booking-history {
                    ul {
                        li {
                            .booking-history-left {
                                .booking-history-top {
                                    .history-top-rep {
                                        p {
                                            span {
                                                margin-left: 0;
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                            .booking-history-right {
                                right: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-gift-vouchers {
        ul {
            li {
                width: 320px;
                .gift-voucher-list {
                    .gift-voucher-con {
                        p {
                            font-size: 18px;
                        }
                        .gift-voucher-bottom {
                            margin-top: 20px;
                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
    .db-user-top-grids {
        .das-sum {
            margin: -12px;
            width: calc(100% + 24px);
            .das-sum-listing {
                padding: 12px;
                .db-sum-card {
                    .db-sum-card-inner {
                        padding-left: 10px;
                    }
                    img {
                        width: 80px;
                        height: 80px;
                    }
                }
            }
        }
    }
    .db-event {
        .das-sum {
            margin: -12px;
            width: calc(100% + 24px);
            .db-event-listing {
                padding: 12px;
                .db-event-list {
                    .db-event-summary {
                        padding: 15px 14px;
                    }
                }
            }
        }
    }
    .db-heading {
        button {
            &.cus-btn {
                min-width: 160px;
            }
        }
    }
    .cus-upcoming-shows {
        .upcoming-shows-inner {
            width: calc(100% + 16px);
            margin: -8px;
            .upcoming-shows-listing {
                padding: 8px;
                .cus-card-inner {
                    button {
                        .p-24 {
                            padding: 15px !important;
                        }
                    }
                    .card-contents {
                        h2 {
                            font-size: 16px;
                        }
                    }
                    .card-bottom-con {
                        .time-card {
                            h5 {
                                font-size: 13px;
                                img {
                                    width: 14px;
                                    height: 14px;
                                }
                            }
                        }
                        .promocode-card {
                            h5 {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-table {
        table {
            tr {
                th,
                td {
                    padding: 14px 20px;
                    white-space: nowrap;
                }
            }
        }
    }
    .db-events-prices-page {
        .event-card-prices {
            .cus-general-prices {
                ul {
                    max-width: 80%;
                }
            }
            .cus-right-btn {
                margin-top: 15px;
            }
        }
    }
    .db-create-venue-page {
        .venue-listing-ul {
            li {
                padding: 10px;
                .venue-listing-inner {
                    padding: 25px;
                    h4 {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .db-create-promocodes {
        .cus-gift-vouchers {
            ul {
                li {
                    .gift-voucher-list {
                        .gift-voucher-con {
                            top: 25px;
                        }
                    }
                }
            }
        }
    }
    .db-promo-code {
        .promo-code-inner {
            ul {
                li {
                    .field-wrap {
                        max-width: 70%;
                    }
                }
            }
        }
    }
    .db-manual-tickets-page {
        .promo-code-inner {
            max-width: 100%;
            ul {
                max-width: 85%;
            }
        }
    }
    .db-web-page {
        .web-page-inner {
            max-width: 100%;
            p {
                font-size: 14px;
            }
        }
    }
    .cus-seat-arrangement {
        min-width: 1050px;
        .cus-items-zone {
            .cus-seats-items {
                padding-left: 10px;
            }
        }
    }
    .cus-seat-scrollable{
        zoom: 20%;
        cursor: zoom-in;
        &:hover {
            zoom: 100%;
        }
    }
    .db-create-venue-seat {
        .dup-rem-btns,
        .update-seat-name-btn {
            button {
                &.cus-btn {
                    min-width: 200px;
                }
            }
        }
        .bottom-btns-group {
            button {
                &.cus-btn {
                    min-width: 180px;
                }
            }
        }
    }
    .custom-web-page {
        .web-page-contents {
            max-width: 100%;
        }
        .cus-promoter-events {
            .db-heading {
                padding: 30px 0 30px 0;
            }
        }
    }
    .db-sellers-page,
    .db-create-promocodes,
    .db-transactions-page,
    .db-tickets-page {
        .db-heading {
            &.res-d-inline-blook {
                display: inline-block;
                h2 {
                    margin-bottom: 15px;
                }
            }
        }
    }
    .db-create-venue-page {
        .venue-listing-sec {
            .db-heading {
                &.res-d-inline-blook {
                    display: inline-block;
                    h2 {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
    .db-tickets-page {
        .ticket-card-info {
            padding: 20px;
        }
        .event-actions {
            display: inline-block;
            width: 100%;
            .actions-icon {
                margin: 0 auto;
            }
            .cus-button-groups {
                flex-direction: column;
                margin-top: 10px;
                width: 100%;
                button {
                    &.cus-btn {
                        min-width: 100%;
                        margin: 6px 0;
                    }
                }
            }
        }
        .tickets-fields {
            ul {
                li {
                    width: 50%;
                }
            }
        }
    }
    .db-transactions-page {
        .db-sub-heading {
            max-width: 100%;
        }
        .date-picker-sec {
            width: 100%;
            padding-right: 0;
            float: left;
            text-align: left;
            .DateRangePicker {
                max-width: 350px;
                .DateRangePickerInput__withBorder {
                    width: 100%;
                }
            }
        }
        table {
            min-width: 800px;
        }
    }
    .db-create-venue-seat {
        .dup-rem-btns,
        .update-seat-name-btn {
            button {
                &.cus-btn {
                    min-width: 200px;
                }
            }
        }
        .bottom-btns-group {
            button {
                &.cus-btn {
                    min-width: 180px;
                }
            }
        }
        .table-zone-sec {
            .field-rep {
                .zone-main-sec {
                    .zone-input-sec {
                        width: calc(100% - 1%);
                    }
                }
                .table-grid-main {
                    .table-rep-grid {
                        padding: 15px;
                    }
                }
            }
        }
        .field-rep {
            .MuiFormControl-root {
                min-width: auto;
            }
        }
        .field-rep {
            .tables {
                .tables-inner {
                    margin: 40px 0px;
                    .circle-sec {
                        font-size: 10px;
                    }
                    .tables-rep-sec {
                        ul {
                            height: 6em;
                            width: 6em;
                            &.chair-count-2 {
                                @include on-circle($item-count: 2, $circle-size: 6em, $item-size: 1.6em);
                                > * {
                                    margin: -2em;
                                    width: 4em;
                                    height: 4em;
                                }
                            }
                            &.chair-count-4 {
                                @include on-circle($item-count: 4, $circle-size: 6em, $item-size: 1.6em);
                                > * {
                                    margin: -0.8em;
                                    width: 1.6em;
                                    height: 1.6em;
                                    padding: 4px;
                                }
                            }
                            &.chair-count-6 {
                                @include on-circle($item-count: 6, $circle-size: 6em, $item-size: 1.6em);
                                > * {
                                    margin: -1em;
                                    width: 2em;
                                    height: 2em;
                                    padding: 6px;
                                }
                            }
                            &.chair-count-8 {
                                @include on-circle($item-count: 8, $circle-size: 6em, $item-size: 1.6em);
                                > * {
                                    margin: -0.8em;
                                    width: 1.6em;
                                    height: 1.6em;
                                    padding: 4px;
                                }
                            }
                            &.chair-count-10 {
                                @include on-circle($item-count: 10, $circle-size: 6em, $item-size: 1.6em);
                                > * {
                                    margin: -0.8em;
                                    width: 1.6em;
                                    height: 1.6em;
                                    padding: 4px;
                                }
                            }
                            &.chair-count-12 {
                                @include on-circle($item-count: 12, $circle-size: 6em, $item-size: 1.6em);
                                > * {
                                    margin: -0.8em;
                                    width: 1.6em;
                                    height: 1.6em;
                                    padding: 4px;
                                }
                            }
                            &.chair-count-14 {
                                @include on-circle($item-count: 14, $circle-size: 6em, $item-size: 1.6em);
                                > * {
                                    margin: -0.8em;
                                    width: 1.6em;
                                    height: 1.6em;
                                    padding: 4px;
                                }
                            }
                            &.chair-count-16 {
                                @include on-circle($item-count: 16, $circle-size: 6em, $item-size: 1.6em);
                                > * {
                                    margin: -0.8em;
                                    width: 1.6em;
                                    height: 1.6em;
                                    padding: 4px;
                                }
                            }
                            &.chair-count-18 {
                                @include on-circle($item-count: 18, $circle-size: 6em, $item-size: 1.6em);
                                > * {
                                    margin: -0.8em;
                                    width: 1.6em;
                                    height: 1.6em;
                                    padding: 4px;
                                }
                            }
                            &.chair-count-20 {
                                @include on-circle($item-count: 20, $circle-size: 6em, $item-size: 1.6em);
                                > * {
                                    margin: -0.8em;
                                    width: 1.6em;
                                    height: 1.6em;
                                    padding: 4px;
                                }
                            }
                            > * {
                                &::before {
                                    font-size: 8px !important;
                                }
                            }
                            li {
                                .seat-inner-img {
                                    svg {
                                        width: 12px;
                                        height: 12px;
                                    }
                                    .seat-empty {
                                        width: 12px;
                                        height: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .venue-page-edit,
    .db-create-venue-seat,
    .cus-booking-page {
        .cus-seat-scrollable {
            overflow: auto;
        }
    }
    .db-sellers-page {
        .cus-table {
            .box-shadow-none {
                max-height: calc(100vh - 255px);
            }
        }
    }
    .custom-chart-main {
        .custom-chart-heading {
            flex-direction: column;
            .chart-head-left,
            .chart-head-right {
                width: 100%;
                display: inline-block;
                text-align: left;
            }
            .chart-head-right {
                margin-top: 10px;
                margin-bottom: 10px;
                > div {
                    margin: 10px 0;
                }
            }
        }
    }
}
@media only screen and (max-width: 1099px) {
    body {
        .cus-modal {
            &.modal-lg {
                .MuiPaper-root {
                    width: 90%;
                }
            }
        }
    }
    .cus-main-slider {
        .slider-left-sec {
            .slider-list {
                .slider-contents {
                    padding: 30px;
                }
            }
        }
        .slider-right-sec {
            .cat-grids-sec {
                ul {
                    li {
                        margin-bottom: 8px;
                        padding-right: 4px;
                        padding-left: 4px;
                        .overlap-cards-con {
                            .overlap-cards-top-img {
                                height: 70px;
                                width: 90px;
                            }
                            .overlap-cards-bottom-img {
                                width: 128px;
                                height: 128px;
                                .overlap-cards-content {
                                    .cat-tittle-show {
                                        width: calc(100% - 20px);
                                    }
                                    .cat-arrow {
                                        width: 20px;
                                        height: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-header {
        header {
            .header-toolbar {
                .cus-menus {
                    li {
                        padding: 8px 15px;
                        a {
                            font-size: 13px;
                        }
                    }
                }
                .cus-sign-search {
                    .sign-in-up-sec {
                        button {
                            &.cus-btn {
                                min-width: 100px;
                                height: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
    .banner-ad-sec {
        .ads-left-con {
            padding: 50px;
        }
        .ads-img-here {
            background-size: 100% 280px;
            img {
                max-height: 280px;
            }
        }
    }
    .cus-booking-page {
        .cus-booking-inner {
            .select-cat-sec {
                .seat-category-selection {
                    padding-bottom: 15px;
                    .user-sel {
                        padding: 15px;
                    }
                    .select-btn {
                        padding: 0 15px;
                    }
                    .header {
                        h5 {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .cus-banner {
        img {
            min-height: 240px;
            object-fit: cover;
        }
        .banner-contents {
            max-width: 80%;
            .banner-btns {
                margin-top: 10px;
            }
        }
    }
    .cus-contact-page {
        .cus-contact-us {
            .contact-us-left-sec {
                .contact-us-left-inner {
                    padding: 30px;
                }
            }
            .contact-us-right-sec {
                .contact-us-right-inner {
                    padding: 30px 30px 0 30px;
                }
            }
        }
    }
    .cus-help-page {
        .cus-help-inner {
            .cus-help-form {
                padding: 40px;
            }
        }
    }
    .cus-profile-page {
        .cus-profile-inner {
            .profile-sidebar {
                padding: 15px;
                .profile-pic {
                    width: 80px;
                    height: 80px;
                    padding: 25px;
                }
                .profile-con {
                    h4 {
                        font-size: 14px;
                    }
                    h6 {
                        font-size: 12px;
                        margin-top: 6px;
                    }
                }
            }
        }
    }
    .db-profile-page {
        .cus-profile-page {
            .cus-profile-inner {
                .profile-sidebar {
                    padding: 15px;
                    .profile-pic {
                        width: 70px;
                        height: 70px;
                        padding: 20px;
                    }
                }
            }
        }
    }
    .cus-tab-list {
        .tab-contents {
            .cus-profile-fileds {
                .tab-inner-head {
                    h4 {
                        font-size: 16px;
                    }
                }
                ul {
                    li {
                        padding: 15px 0;
                    }
                }
            }
            .cus-booking-history {
                ul {
                    li {
                        display: inline-block;
                        .booking-history-left {
                            width: 100%;
                            padding-right: 0;
                        }
                        .booking-history-right {
                            width: 100%;
                            display: flex;
                            .right-links-rep {
                                display: flex;
                                margin: 5px;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
            .tab-inner-head {
                h4 {
                    font-size: 16px;
                }
            }
            .wallet-list {
                ul {
                    li {
                        .wallet-list-inner {
                            .wallet-head {
                                p {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-gift-vouchers {
        ul {
            li {
                width: 260px;
                .gift-voucher-list {
                    .gift-voucher-con {
                        top: 15px;
                        p {
                            font-size: 16px;
                        }
                        .gift-voucher-bottom {
                            margin-top: 20px;
                            p {
                                font-size: 12px;
                            }
                            button {
                                &.cus-btn {
                                    min-width: 130px;
                                    margin-top: 4px;
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .cus-accordion {
            .accordion-listing {
                .accordion-summary {
                    h3 {
                        font-size: 16px;
                    }
                }
                .accordion-details {
                    .cus-auth-form {
                        min-width: 70%;
                    }
                }
            }
        }
    }
    .db-event {
        .das-sum {
            .db-event-listing {
                .db-event-list {
                    .db-event-summary {
                        .db-date-time {
                            ul {
                                display: inline-block;
                                li {
                                    padding: 3px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .db-user-top-grids {
        .das-sum {
            .das-sum-listing {
                .db-sum-card {
                    padding: 25px 15px;
                    .db-sum-card-inner {
                        h3 {
                            font-size: 32px;
                        }
                        p {
                            font-size: 16px;
                        }
                    }
                    img {
                        width: 70px;
                        height: 70px;
                    }
                }
            }
        }
    }
    .db-bar-events {
        .db-upcoming-events {
            padding: 18px;
        }
    }
    .db-section-heading {
        h4 {
            font-size: 16px;
        }
    }
    .container-spacing {
        padding: 30px;
    }
    .cus-upcoming-shows {
        .upcoming-shows-inner {
            .upcoming-shows-listing {
                .cus-card-inner {
                    .card-img-sec {
                        height: 150px;
                    }
                }
            }
        }
    }
    .db-add-events-page {
        .db-add-event-con {
            .sidebar-add-event {
                width: 140px;
                .add-event-img {
                    height: 130px;
                }
            }
            .db-add-event-form {
                width: calc(100% - 140px);
                padding-left: 30px;
                .event-field {
                    max-width: 100%;
                }
            }
        }
    }
    .db-events-prices-page {
        .event-card-prices {
            .cus-general-prices {
                ul {
                    max-width: 100%;
                    li {
                        margin-right: 10px;
                        max-width: calc(50% - 10px);
                        &:nth-child(even) {
                            margin-right: 0;
                            margin-left: 10px;
                        }
                    }
                }
            }
            .cus-booking-fee {
                ul {
                    li {
                        max-width: calc(50% - 20px);
                        flex: 1;
                        margin-right: 20px;
                        &:last-child {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
    }
    .db-create-venue-page {
        .venue-listing-ul {
            li {
                width: 50%;
            }
        }
    }
    .db-create-promocodes {
        .cus-gift-vouchers {
            ul {
                li {
                    .gift-voucher-list {
                        .gift-voucher-con {
                            top: 15px;
                            .gift-voucher-bottom {
                                button {
                                    &.cus-btn {
                                        font-size: 14px;
                                        height: 34px;
                                    }
                                }
                                p {
                                    font-size: 11px;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .db-manual-tickets-detail {
        .manual-ticket-sec {
            .manual-ticket-img {
                img {
                    max-width: 350px;
                }
            }
        }
    }
    .auth-wrapper {
        .split {
            .centered {
                .auth-wrapper-content {
                    bottom: 15%;
                    h2 {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .cus-sellers-events {
        .cus-tab-list {
            .tab-contents {
                .tab-inner-head {
                    button {
                        &.cus-btn {
                            top: 100px;
                            height: 36px;
                        }
                    }
                }
            }
        }
    }
    .ad-override-event-fees {
        .db-sub-heading {
            max-width: 100%;
        }
    }
    .db-create-venue-seat {
        .table-zone-sec {
            .field-rep {
                .table-grid-main {
                    .table-rep-grid {
                        width: 100%;
                        margin: 2% 0 0 0;
                    }
                }
            }
        }
    }
    .custom-chart-main {
        .custom-chart-heading {
            .chart-head-left {
                h6 {
                    font-size: 16px;
                }
            }
        }
    }
}
@media only screen and (max-width: 959px) {
    .cus-header {
        padding: 10px 0;
        .cus-menus {
            display: none;
        }
        .header-container {
            .cus-logo {
                img {
                    width: 160px;
                }
            }
            button {
                margin-right: 0;
            }
        }
    }
    .custom-menu {
        .MuiDrawer-paperAnchorLeft {
            width: 240px;
        }
        .cus-head-logo {
            padding: 0 12px;
            button {
                margin: 0;
            }
        }
        .cus-menus {
            width: 100%;
            display: inline-block;
            li {
                padding: 0;
                margin-bottom: 10px;
                a {
                    white-space: nowrap;
                    color: $dark-grey-color;
                    font-size: 14px;
                    font-weight: 500;
                    width: 100%;
                    display: inline-block;
                    padding: 8px 24px;
                    font-family: "Montserrat", sans-serif !important;
                    &:focus {
                        text-decoration: none;
                    }
                }
            }
        }
        .user-login {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            background: $white-color;
            align-items: center;
            padding: 20px 20px;
            .user-icon {
                width: 36px;
                height: 36px;
                display: flex;
                border-radius: 50%;
                background: $blue-color;
                align-items: center;
                justify-content: center;
                margin-right: 14px;
                img {
                    width: 16px;
                    height: 16px;
                }
            }
            span {
                color: $blue-color;
                font-weight: 600;
                font-size: 16px;
                width: calc(100% - 50px);
            }
        }
        .sidebar-menus {
            padding: 10px;
            .sidepanel-item {
                border-radius: 8px;
                margin-bottom: 8px;
                padding: 6px 14px;
                .MuiListItemIcon-root {
                    min-width: 40px;
                }
                img {
                    width: 22px;
                    height: 22px;
                    &.icon-white {
                        display: none;
                    }
                    &.icon-grey {
                        display: block;
                    }
                }
                span {
                    color: $grey-color;
                    font-weight: 600;
                    font-size: 16px;
                }
                &.active {
                    background-color: $blue-color;
                    img {
                        &.icon-white {
                            display: block;
                        }
                        &.icon-grey {
                            display: none;
                        }
                    }
                    span {
                        color: $white-color;
                    }
                }
            }
        }
    }
    .cus-sec-heading {
        h2 {
            font-size: 26px;
        }
    }
    body {
        .cus-modal {
            &.modal-md {
                .MuiPaper-root {
                    width: 90%;
                }
            }
        }
        .user-dropdown {
            .MuiMenu-paper {
                margin-left: 0px;
                top: 62px !important;
            }
        }
    }
    .cus-main-slider {
        .slider-left-sec {
            .slider-list {
                img {
                    max-height: 350px;
                    object-fit: cover;
                }
                .slider-contents {
                    h5 {
                        font-size: 16px;
                    }
                    .slider-btns {
                        button {
                            min-width: 180px;
                        }
                    }
                }
            }
        }
        .slider-right-sec {
            .cat-grids-sec {
                margin-top: 16px;
                ul {
                    li {
                        margin-bottom: 32px;
                        padding-right: 16px;
                        padding-left: 16px;
                        .overlap-cards-con {
                            .overlap-cards-top-img {
                                height: 130px;
                                width: 180px;
                            }
                            .overlap-cards-bottom-img {
                                width: 100%;
                                height: 200px;
                                border-radius: 20px;
                                overflow: hidden;
                                background-size: cover !important;
                                background-position: inherit !important;
                                .overlap-cards-content {
                                    padding: 15px 20px 15px 20px;
                                    .cat-tittle-show {
                                        width: calc(100% - 30px);
                                        h4 {
                                            font-size: 18px;
                                        }
                                        p {
                                            font-size: 14px;
                                        }
                                    }
                                    .cat-arrow {
                                        width: 30px;
                                        height: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .show-detail-page {
        .cus-show-detail-slider {
            .show-detail-slider {
                .detail-slider-con {
                    h5 {
                        font-size: 16px;
                    }
                    .detail-slider-btns {
                        button {
                            min-width: 180px;
                        }
                    }
                }
            }
        }
    }
    .banner-ad-sec {
        margin: 10px 0 50px;
        .ads-left-con {
            padding: 40px;
            background-size: 80% 150px;
            background-position: right 50px;
        }
        .ads-right-img {
            display: none;
        }
    }
    .home-about-sec {
        margin-bottom: 0;
        .cus-sec-heading {
            padding-top: 0;
        }
        .about-sec-left {
            img {
                max-height: 400px;
            }
        }
    }
    .cus-footer {
        padding: 30px 0px 15px 0px;
        .footer-middle-sec {
            h3 {
                margin-bottom: 15px;
            }
            .footer-logo-sec {
                padding-right: 0;
            }
        }
    }
    .cus-auth-page {
        .cus-auth-inner {
            .cus-auth-con {
                position: relative;
                z-index: 77;
            }
            &::after,
            &::before {
                width: 300px;
                height: 300px;
                opacity: 0.4;
            }
            &::after {
                left: -100px;
            }
            &::before {
                right: -100px;
            }
        }
    }
    .show-detail-page {
        .cus-show-detail-slider {
            .show-detail-slider {
                padding: 0 5px;
                .detail-slider-img {
                    img {
                        min-height: 300px;
                        object-fit: cover;
                    }
                }
                .detail-slider-con {
                    max-width: 80%;
                    padding: 30px 0 30px 50px;
                }
            }
        }
    }
    .cus-upcoming-shows {
        padding: 0;
    }
    .pick-your-venue-page {
        .filter-by-category {
            width: 250px;
        }
        .cus-page-search {
            max-width: 250px;
        }
    }
    .single-show-page {
        .artist-cards-inner {
            padding-bottom: 0;
            .cus-artist-cards {
                ul {
                    li {
                        width: 120px;
                        margin-right: 20px;
                        margin-bottom: 20px;
                        .artist-card {
                            .artist-card-img {
                                width: 100px;
                                height: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-booking-page {
        .cus-booking-inner {
            .booking-head-locations {
                h3 {
                    font-size: 24px;
                }
            }
            .select-cat-sec {
                h2 {
                    font-size: 20px;
                }
                .seat-category-selection {
                    margin-bottom: 0;
                }
            }
        }
    }
    .sell-ticket-page {
        .sell-tickets-listing {
            .tickets-img {
                margin-bottom: 30px;
                width: 300px;
                height: 300px;
            }
            .align-center {
                .tickets-con {
                    order: 1;
                    text-align: center;
                }
            }
            .tickets-con {
                p {
                    margin-bottom: 12px;
                }
            }
        }
    }
    .cus-banner {
        .banner-contents {
            h2 {
                font-size: 25px;
            }
        }
    }
    .cus-page-search {
        max-width: 60%;
    }
    .cus-faq-page {
        margin-bottom: 0;
        .cus-have-question {
            margin: 20px 0 0 0;
            .cus-sec-sub-heading {
                p {
                    font-size: 14px;
                }
            }
        }
        .cus-faq-con {
            .faq-options {
                ul {
                    max-width: 100%;
                    li {
                        margin: 2px 0px;
                        h3 {
                            font-size: 14px;
                            padding: 15px;
                        }
                    }
                }
            }
        }
    }
    .cus-accordion {
        .accordion-listing {
            .accordion-summary {
                padding: 10px 0;
                h3 {
                    font-size: 14px;
                }
            }
            .accordion-details {
                padding: 0 0 20px 0;
            }
        }
    }
    .cus-contact-page {
        .cus-contact-us {
            .contact-us-right-sec {
                .contact-us-right-inner {
                    padding: 30px 0 0 0;
                }
            }
        }
    }
    .cus-listing-design {
        ul {
            li {
                padding: 10px 6px 0 35px;
            }
        }
    }
    .cus-static-page {
        padding-bottom: 0;
    }
    .cus-help-page {
        padding-bottom: 0;
        .cus-help-inner {
            .cus-help-form {
                max-width: 80%;
                margin: 20px auto;
                .cus-help-form-inner {
                    max-width: 100%;
                }
            }
        }
    }
    .cus-booking-page {
        .cus-booking-accordion {
            .cus-accordion {
                .accordion-listing {
                    .accordion-summary {
                        padding: 10px 0;
                        h3 {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .payment-options-sec {
            .payment-left-grid {
                padding-right: 24px;
            }
        }
    }
    .cus-profile-page {
        .cus-profile-inner {
            .profile-sidebar {
                padding: 20px;
                max-width: 250px;
                margin: 0 auto;
                display: block;
                .profile-pic {
                    width: 100px;
                    height: 100px;
                    padding: 25px;
                }
                .profile-con {
                    h4 {
                        font-size: 16px;
                    }
                    h6 {
                        font-size: 14px;
                    }
                }
            }
            .profile-right-sec {
                padding-left: 0;
                margin-top: 30px;
            }
        }
    }
    .db-profile-page {
        .cus-profile-page {
            margin-bottom: 0;
            .cus-profile-inner {
                .profile-sidebar {
                    padding: 20px;
                    max-width: 250px;
                    margin: 0 auto;
                    display: block;
                    .profile-pic {
                        width: 100px;
                        height: 100px;
                        padding: 25px;
                    }
                    .profile-con {
                        h4 {
                            font-size: 16px;
                        }
                        h6 {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .cus-tab-list {
            .tab-contents {
                .cus-booking-history {
                    ul {
                        li {
                            .booking-history-left {
                                .booking-history-top {
                                    .history-top-rep {
                                        margin-right: 30px;
                                        p {
                                            span {
                                                margin-left: 12px;
                                                display: inline-block;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .confirm-booking-modal {
        padding: 10px 10% 42px 10%;
    }
    .db-main-container {
        .db-main-con-inner {
            .db-right-container {
                width: 100%;
                padding: 0 24px;
            }
        }
    }
    .db-user-top-grids {
        .das-sum {
            margin: -8px;
            width: calc(100% + 8px);
            .das-sum-listing {
                padding: 8px;
                .db-sum-card {
                    padding: 20px 12px;
                    .db-sum-card-inner {
                        padding-left: 5px;
                        h3 {
                            font-size: 28px;
                        }
                        p {
                            font-size: 14px;
                        }
                    }
                    img {
                        width: 60px;
                        height: 60px;
                    }
                }
            }
        }
    }
    .db-top-bar {
        margin: 15px 0;
        .cus-page-search {
            max-width: 100%;
        }
    }
    .db-event {
        .das-sum {
            .db-event-listing {
                .db-event-list {
                    .db-event-summary {
                        padding: 20px 24px;
                        .db-date-time {
                            ul {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
    }
    .db-bar-events {
        padding: 0;
        .db-upcoming-events {
            padding: 20px;
            margin-top: 25px;
            margin-bottom: 25px;
        }
    }
    .cus-upcoming-shows {
        .upcoming-shows-inner {
            .upcoming-shows-listing {
                .cus-card-inner {
                    .card-contents {
                        h2 {
                            font-size: 16px;
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
    .single-show-page {
        .single-show-inner {
            padding-bottom: 0;
            .show-detail-grid {
                padding-top: 0;
            }
            .show-full-img {
                img {
                    border-radius: 10px;
                }
            }
        }
    }
    .db-section-heading {
        h4 {
            font-size: 18px;
        }
    }
    .cus-sellers-events {
        .db-heading {
            padding: 0 0 30px 0;
        }
        .cus-upcoming-shows {
            padding-bottom: 20px;
        }
    }
    .rep-tb-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .db-tickets-page {
        .ticket-card-info {
            min-height: auto;
            display: inline-block;
        }
        .tickets-fields {
            ul {
                li {
                    width: 33.33%;
                }
            }
        }
        .event-actions {
            display: flex;
            .cus-button-groups {
                flex-direction: row;
                margin-top: 0;
                button {
                    &.cus-btn {
                        min-width: auto;
                        margin: 0 6px;
                    }
                }
            }
        }
    }
    .ad-report-page {
        .cus-bottom-price {
            ul {
                li {
                    h4,
                    h5 {
                        font-size: 16px;
                    }
                    h2,
                    h3 {
                        font-size: 22px;
                    }
                }
            }
        }
    }
    .cus-seat-scrollable {
        overflow: auto;
    }
    .__app_container__ {
        .__global_loader__ {
            div {
                div {
                    width: 100px !important;
                }
            }
        }
    }
    .event-filter-dropdown {
        .MuiMenu-paper {
            top: 152px !important;
        }
    }
    .db-sellers-page {
        .cus-table {
            .box-shadow-none {
                max-height: none;
            }
        }
    }
    .pick-your-venue-page {
        .pick-your-venue-sec {
            .cus-venue-list-sec {
                .venue-listing {
                    .venue-con {
                        h4 {
                            min-height: auto;
                            -webkit-line-clamp: inherit;
                            -webkit-box-orient: inherit;
                            overflow: visible;
                        }
                        p {
                            span {
                                min-height: auto;
                                -webkit-line-clamp: inherit;
                                -webkit-box-orient: inherit;
                                overflow: visible;
                            }
                        }
                    }
                }
            }
        }
    }
    .db-create-venue-seat {
        .db-heading {
            padding: 15px 0 5px 0;
        }
        .field-rep {
            .po-relative {
                margin-bottom: 5px !important;
            }
        }
        .table-zone-sec {
            .field-rep {
                .zone-main-sec {
                    .zone-input-sec {
                        flex-direction: column;
                        background: $white-color;
                        margin: 0;
                        width: 100%;
                        padding: 20px;
                        border-radius: 8px;
                        .zone-input-here {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                        .zone-btn-here {
                            .zone-btn-wrap {
                                button {
                                    &.cus-btn {
                                        margin-left: 0;
                                        margin-right: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .modal-notification {
        .MuiPaper-root {
            top: 65px !important;
            .notification-dropdown {
                max-height: calc(100vh - 80px);
            }
        }
    }
    .custom-chart-main {
        .custom-chart-heading {
            .chart-head-left {
                h6 {
                    font-size: 18px;
                }
            }
        }
    }
    .db-transactions-page {
        .cus-table {
            .cus-table-container {
                max-height: 450px;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    body {
        .cus-modal {
            &.modal-sm {
                .MuiPaper-root {
                    width: 90%;
                }
            }
            .modal-form {
                .add-fileds-modal {
                    padding: 0 5%;
                }
            }
        }
    }
    .show-detail-page {
        .cus-show-detail-slider {
            .show-detail-slider {
                .detail-slider-con {
                    max-width: 90%;
                    padding: 15px 0 15px 30px;
                }
            }
        }
    }
    .single-show-page {
        .artist-cards-inner {
            .cus-artist-cards {
                ul {
                    li {
                        width: calc(25% - 20px);
                    }
                }
            }
        }
    }
    .cus-banner {
        .banner-contents {
            max-width: 100%;
            padding: 30px;
        }
    }
    .cus-help-page {
        .cus-help-inner {
            .cus-help-form {
                max-width: 90%;
                padding: 30px;
            }
        }
    }
    .cus-booking-page {
        .cus-booking-accordion {
            .contact-detail-sec {
                ul {
                    display: inline-block;
                    li {
                        margin-right: 0;
                        margin-bottom: 15px;
                    }
                }
            }
            .promotional-code-sec {
                ul {
                    li {
                        .promo-field {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .cus-gift-vouchers {
        ul {
            li {
                width: 220px;
                .gift-voucher-list {
                    .gift-voucher-con {
                        p {
                            font-size: 14px;
                        }
                        .gift-voucher-bottom {
                            margin-top: 8px;
                            p {
                                font-size: 10px;
                            }
                            button {
                                &.cus-btn {
                                    min-width: 120px;
                                    margin-top: 3px;
                                    height: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .cus-accordion {
            .accordion-listing {
                .accordion-summary {
                    h3 {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .cus-tab-list {
        .tab-contents {
            .tab-inner-head {
                h4 {
                    font-size: 14px;
                }
            }
            .wallet-list {
                ul {
                    li {
                        .wallet-list-inner {
                            .wallet-head {
                                img {
                                    width: 24px;
                                    height: 24px;
                                }
                                p {
                                    font-size: 14px;
                                }
                            }
                            .available-bal {
                                p {
                                    font-size: 12px;
                                    &.bal-here {
                                        font-size: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .cus-profile-fileds {
                .tab-inner-head {
                    h4 {
                        font-size: 14px;
                    }
                }
            }
            .cus-booking-history {
                ul {
                    li {
                        .booking-history-left {
                            .booking-history-top {
                                .history-top-rep {
                                    p {
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .confirm-password-form {
        padding: 10px 5% 60px 5%;
    }
    .confirm-booking-modal {
        padding: 0 0 30px 0;
        .your-ticket-sec {
            padding: 0 20px;
        }
    }
    .db-heading,
    .db-sub-heading {
        &.res-d-inline-blook {
            display: inline-block;
            .heading-btns-group {
                margin-top: 10px;
            }
        }
    }
    .event-actions {
        margin-top: 8px;
        display: inline-block;
        width: 100%;
        text-align: center;
        .actions-icon {
            margin: 5px;
            display: inline-block;
            line-height: 42px;
            text-align: center;
        }
        .cus-button-groups {
            margin-top: 8px;
        }
    }
    .db-add-events-page {
        .db-add-event-con {
            .sidebar-add-event {
                width: 120px;
                .add-event-img {
                    height: 110px;
                }
                h4 {
                    font-size: 12px;
                }
            }
            .db-add-event-form {
                width: calc(100% - 120px);
                padding-left: 20px;
            }
        }
    }
    .db-events-prices-page {
        .event-card-prices {
            .cus-booking-fee {
                ul {
                    li {
                        max-width: 100%;
                        margin: 0 0 20px 0;
                        &:nth-child(even) {
                            margin: 0 0 20px 0;
                        }
                    }
                }
            }
            .cus-right-btn {
                button {
                    &.cus-btn {
                        min-width: 160px;
                    }
                }
            }
        }
    }
    .db-create-promocodes {
        .cus-gift-vouchers {
            ul {
                li {
                    .gift-voucher-list {
                        .gift-voucher-con {
                            .gift-voucher-bottom {
                                margin-top: 20px;
                                button {
                                    &.cus-btn {
                                        font-size: 12px;
                                        height: 32px;
                                    }
                                }
                                p {
                                    font-size: 10px;
                                    margin-top: 4px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .db-manual-tickets-page {
        .promo-code-inner {
            ul {
                max-width: 100%;
                li {
                    .rep-filed {
                        .rep-adult {
                            .rep-field-half {
                                width: 50%;
                                .MuiFormControl-root {
                                    min-width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .create-ticket-btn {
            button {
                &.cus-btn {
                    min-width: 160px;
                }
            }
        }
        &.ad-sallers-page {
            .db-heading {
                padding: 0 0 10px 0;
            }
            .promo-code-inner {
                .db-sub-heading {
                    padding: 0 0 5px 0;
                }
            }
        }
    }
    .db-web-page {
        .web-page-inner {
            .web-page-container {
                .event-img-con {
                    width: 120px;
                }
                .web-page-right {
                    width: calc(100% - 120px);
                    padding-left: 20px;
                }
            }
        }
    }
    .db-transactions-page {
        .db-sub-heading {
            padding-top: 10px;
        }
        .date-picker-sec {
            width: auto;
            padding-right: 0;
            float: left;
            text-align: left;
        }
        .db-heading {
            button {
                &.cus-btn {
                    margin-top: 15px;
                }
            }
        }
    }
    .cus-table {
        zoom: 40%;
        cursor: zoom-in;
        &:hover{
            zoom: 100%;
            table{
                tr{
                    th, td{
                        font-size: 14px;
                        padding: 14px 20px;
                    }
                }
            }
        }
        table{
            min-width: 100%;
            tr{
                th, td{
                    font-size: 8px;
                    padding: 14px 10px;
                    min-width: auto;
                }
            }
            tbody, tbody {
                overflow: visible;
            }
        }
    }
    .db-sellers-page{
        .cus-table {
            zoom: 30%;
            cursor: zoom-in;
            &:hover{
                zoom: 100%;
                .sellers-action-buttons{
                    button{
                        &.cus-btn {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .sellers-action-buttons{
            button{
                &.cus-btn {
                    font-size: 8px;
                    min-width: auto;
                }
            }
        }
    }
    .ad-expenditure-page{
        .download-btn{
            button{
                &.cus-btn{
                    font-size: 8px;
                    min-width: auto;
                }
            }
        }
        .cus-table {
            &:hover{
                .download-btn{
                    button{
                        &.cus-btn{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .db-sellers-page,
    .pick-your-venue-page {
        .db-heading {
            &.res-d-inline-blook {
                .d-flex {
                    &.align-center {
                        width: 100%;
                        display: inline-block;
                    }
                }
            }
            .MuiFormControl-root {
                min-width: calc(100% - 168px);
            }
            button {
                &.cus-btn {
                    margin: 0 0 0 8px;
                }
            }
            .cus-page-search {
                margin: 15px 0 0 0;
                max-width: 100%;
            }
        }
    }
    .db-create-venue-page {
        .venue-listing-sec {
            .db-heading {
                &.res-d-inline-blook {
                    .d-flex {
                        &.align-center {
                            width: 100%;
                            display: inline-block;
                        }
                    }
                }
                .MuiFormControl-root {
                    min-width: 100%;
                }
                button {
                    &.cus-btn {
                        margin: 0;
                        min-width: 100%;
                    }
                }
                .cus-page-search {
                    margin: 15px 0 0 0;
                    max-width: 100%;
                }
            }
        }
    }
    .pick-your-venue-page {
        &.db-venue-page {
            .db-heading {
                button {
                    &.cus-btn {
                        margin: 15px 0 0 0;
                        width: 100%;
                    }
                }
            }
        }
    }
    .db-create-promocodes {
        .db-heading {
            &.res-d-inline-blook {
                .d-flex {
                    &.align-center {
                        width: 100%;
                        display: flex;
                    }
                }
            }
            .MuiFormControl-root {
                min-width: calc(100% - 168px);
            }
            button {
                &.cus-btn {
                    margin: 0;
                }
            }
            .cus-page-search {
                margin: 0 0 0 8px;
                max-width: 100%;
            }
        }
    }
    .db-transactions-page,
    .db-tickets-page {
        .db-heading {
            &.res-d-inline-blook {
                .d-flex {
                    &.align-center {
                        width: 100%;
                        display: inline-block;
                    }
                }
            }
            .MuiFormControl-root {
                min-width: 100%;
                margin: 0;
            }
            button {
                &.cus-btn {
                    margin: 0 0 15px 0;
                    min-width: 100%;
                }
            }
            .cus-page-search {
                margin: 15px 0 0 0;
                max-width: 100%;
            }
        }
        .date-picker-sec {
            width: 100%;
            margin-top: 15px;
            .DateRangePickerInput__withBorder {
                width: 100%;
                .DateInput.DateInput_1 {
                    width: 39%;
                }
            }
        }
        .cus-table {
            table {
                tbody {
                    max-height: 450px;
                    overflow: auto;
                }
            }
        }
    }
    .ad-your-events {
        &.cus-promoter-events {
            .db-heading {
                h2 {
                    margin-bottom: 10px;
                }
                button {
                    &.cus-btn {
                        margin: 0 8px 0 0;
                    }
                }
            }
        }
    }
    .profit-loss-form {
        .changes-pass-icon {
            img {
                width: 60px;
                height: 60px;
            }
        }
    }
    .ad-report-page {
        .cus-bottom-price {
            ul {
                li {
                    margin: 10px 0px;
                    h4,
                    h5 {
                        font-size: 14px;
                    }
                    h2,
                    h3 {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .ad-add-seller-page {
        &.db-add-events-page {
            .db-add-event-con {
                .db-add-event-form {
                    margin-top: 0;
                    .event-field {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .cus-seat-arrangement {
        .cus-seats-options {
            max-width: 320px;
            ul {
                margin: 30px 0 10px 0;
                li {
                    font-size: 12px;
                }
            }
        }
        .cus-items-zone {
            .cus-seats-items {
                padding-left: 0px;
                .cus-seats-grid {
                    .cus-seat-plan {
                        padding-left: 30px;
                        .cus-row-name {
                            left: -5px;
                        }
                    }
                }
            }
        }
    }
    .venue-page-edit {
        .db-heading {
            padding-bottom: 30px;
            .heading-btns-group {
                button {
                    &.cus-btn {
                        min-width: 100px;
                        font-size: 14px;
                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
            .d-block-sm {
                display: block !important;
            }
        }
        .d-none-sm {
            display: none !important;
        }
        .db-sub-heading {
            padding: 10px 0 5px;
        }
    }
    .db-create-venue-seat {
        .field-rep {
            .cus-seat-plan {
                overflow: auto;
                zoom: 35%;
                cursor: zoom-in;
                &:hover {
                    zoom: 100%;
                }
            }
            .tables {
                min-width: 550px;
            }
            .cus-seats-grid {
                .cus-seat-plan {
                    overflow: visible;
                }
            }
        }
        .table-zone-sec {
            .field-rep {
                .zone-main-sec {
                    .zone-input-sec {
                        display: inline-block;
                        button {
                            &.cus-btn {
                                margin-right: 0;
                            }
                        }
                        .zone-input-here {
                            width: 100%;
                        }
                    }
                }
                button {
                    &.cus-btn {
                        height: 48px;
                        margin: 15px 15px 0 0;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .cus-show-filters {
        .shows-filters-container {
            max-height: 500px;
        }
    }
    .__app_container__ {
        .__global_loader__ {
            div {
                div {
                    width: 80px !important;
                }
            }
        }
    }
    .event-filter-dropdown {
        .MuiMenu-paper {
            top: 192px !important;
        }
    }
    .cus-promoter-events-add {
        .db-events-prices-page {
            .event-card-prices {
                .cus-booking-fee {
                    ul {
                        li {
                            max-width: 100%;
                            margin-right: 20px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 599px) {
    .container-spacing {
        padding: 20px;
    }
    .cus-sec-heading {
        h2 {
            font-size: 24px;
        }
    }
    .cus-modal {
        .cus-sec-heading {
            h2 {
                font-size: 22px;
            }
        }
    }
    body {
        .cus-modal {
            &.modal-xs {
                .MuiPaper-root {
                    width: 90%;
                }
            }
            .modal-title {
                padding: 0;
                position: absolute;
                right: 5px;
                top: 5px;
                z-index: 8888;
            }
            .modal-form {
                padding: 10px 0 20px 0;
                .db-sub-heading {
                    padding: 20px 0 10px 0;
                }
                .add-fileds-modal {
                    padding: 0;
                }
            }
        }
    }
    .cus-main-slider {
        .slider-left-sec {
            .slider-list {
                img {
                    max-height: 100%;
                    object-fit: contain;
                }
                .slider-contents {
                    padding: 20px;
                    h5 {
                        font-size: 14px;
                    }
                    h2 {
                        font-size: 24px;
                    }
                    p {
                        font-size: 12px;
                    }
                    .slider-btns {
                        display: none;
                    }
                }
            }
        }
        .slider-right-sec {
            .cat-grids-sec {
                ul {
                    li {
                        padding-right: 8px;
                        padding-left: 8px;
                        .overlap-cards-con {
                            .overlap-cards-top-img {
                                height: 100px;
                                width: 130px;
                            }
                            .overlap-cards-bottom-img {
                                height: 180px;
                            }
                        }
                    }
                }
            }
        }
    }
    .banner-ad-sec {
        .ads-left-con {
            padding: 30px;
        }
    }
    .home-about-sec {
        .about-sec-right {
            button.cus-btn {
                margin-top: 10px;
            }
        }
    }
    .cus-auth-page {
        .auth-logo {
            margin: 20px 0 0 0;
            min-height: 65px;
            img {
                max-width: 150px;
            }
        }
        .cus-auth-inner {
            min-height: calc(100vh - 85px);
            &::after,
            &::before {
                width: 250px;
                height: 250px;
            }
            .cus-auth-con {
                .auth-social-media {
                    ul {
                        li {
                            img {
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }
                }
                .otp-fields-sec {
                    ul {
                        li {
                            margin: 15px 10px;
                            input {
                                padding: 15px 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-tabs {
        padding: 20px 30px 30px;
        header {
            button {
                font-size: 14px;
            }
        }
    }
    .cus-footer {
        .footer-middle-sec {
            .footer-menus {
                ul {
                    margin-bottom: 10px;
                }
            }
        }
    }
    .show-detail-page {
        .cus-show-detail-slider {
            .show-detail-slider {
                .detail-slider-con {
                    .detail-slider-btns {
                        display: none;
                    }
                }
            }
        }
    }
    .pick-your-venue-page {
        .filter-by-category {
            width: 100%;
            margin-bottom: 8px;
        }
        .cus-page-search {
            max-width: 100%;
            margin-top: 8px;
        }
    }
    .single-show-page {
        .artist-cards-inner {
            .cus-artist-cards {
                ul {
                    li {
                        width: calc(33.33% - 20px);
                    }
                }
            }
        }
    }
    .cus-booking-page {
        .cus-booking-inner {
            .select-cat-sec {
                h2 {
                    font-size: 18px;
                }
            }
            .booking-head-locations {
                h3 {
                    font-size: 20px;
                }
                h4 {
                    text-align: left;
                    align-items: self-start;
                }
            }
        }
    }
    .sell-ticket-page {
        .sell-tickets-listing {
            margin-bottom: 50px;
            .tickets-img {
                width: 250px;
                height: 250px;
            }
        }
    }
    .cus-banner {
        .banner-contents {
            h2 {
                font-size: 20px;
            }
            .banner-btns {
                display: none;
            }
        }
    }
    .cus-take-away {
        .take-away-img {
            img {
                width: 80px;
                height: 80px;
            }
        }
    }
    .cus-page-search {
        max-width: 100%;
        margin: 10px 0 25px;
    }
    .cus-faq-page {
        .cus-have-question {
            .cus-sec-sub-heading {
                p {
                    br {
                        display: none;
                    }
                }
            }
        }
    }
    .fixed-social-media {
        width: 40px;
        ul {
            li {
                img {
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }
    .cus-help-page {
        .cus-help-inner {
            .cus-help-form {
                max-width: 100%;
                padding: 20px;
            }
        }
    }
    .cus-booking-page {
        .cus-booking-accordion {
            .cus-accordion {
                .accordion-listing {
                    .accordion-summary {
                        h3 {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .booking-summary {
            padding: 20px 5px;
        }
    }
    .cus-profile-page {
        .cus-profile-inner {
            .profile-sidebar {
                padding: 20px 15px;
                max-width: 200px;
            }
        }
    }
    .db-profile-page {
        .cus-profile-page {
            .cus-profile-inner {
                .profile-sidebar {
                    padding: 20px 15px;
                    max-width: 200px;
                }
            }
        }
        .cus-tab-list {
            .tab-contents {
                .cus-booking-history {
                    ul {
                        li {
                            .booking-history-left {
                                .booking-history-top {
                                    .history-top-rep {
                                        margin-right: 0;
                                        p {
                                            span {
                                                margin-left: 0;
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                            .booking-history-right {
                                right: 20px;
                                top: 70px;
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-tab-list {
        .tab-contents {
            .cus-profile-fileds {
                ul {
                    li {
                        display: inline-block;
                        .left-label {
                            width: 100%;
                        }
                        .right-field {
                            width: 100%;
                            margin-top: 5px;
                        }
                    }
                }
            }
            .cus-booking-history {
                ul {
                    li {
                        padding: 20px;
                        .booking-history-left {
                            .booking-history-top {
                                .history-top-rep {
                                    margin-right: 0;
                                    flex: 1;
                                    p {
                                        font-size: 12px;
                                        white-space: nowrap;
                                        span {
                                            margin-left: 0;
                                            display: block;
                                        }
                                    }
                                }
                            }
                            .booking-history-details {
                                display: inline-block;
                                .booking-history-img {
                                    margin: 0;
                                }
                                .history-details-right {
                                    width: 100%;
                                    margin-top: 15px;
                                }
                            }
                        }
                        .booking-history-right {
                            display: inline-block;
                            .right-links-rep {
                                margin: 8px 0;
                            }
                        }
                    }
                }
                .tab-inner-head {
                    display: inline-block;
                    width: 100%;
                }
                .past-month-filter {
                    width: 100%;
                    margin-top: 15px;
                }
            }
        }
    }
    .cus-gift-vouchers {
        ul {
            li {
                width: 100%;
                margin: 0 0 15px 0;
                &:nth-last-child(2) {
                    margin-bottom: 15px;
                }
                .gift-voucher-list {
                    .gift-voucher-img {
                        img {
                            height: 170px;
                        }
                    }
                    .gift-voucher-con {
                        top: 30px;
                        p {
                            font-size: 16px;
                        }
                        .gift-voucher-bottom {
                            margin-top: 35px;
                            p {
                                font-size: 12px;
                            }
                            button {
                                &.cus-btn {
                                    min-width: 140px;
                                    margin-top: 10px;
                                    height: 32px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .cus-accordion {
            .accordion-listing {
                .accordion-details {
                    .cus-auth-form {
                        min-width: 100%;
                    }
                }
            }
        }
    }
    .confirm-password-form {
        padding: 0 0 24px 0;
        > div {
            padding: 0;
        }
        .changes-pass-icon {
            img {
                width: 80px;
                height: 80px;
            }
        }
    }
    .confirm-booking-modal {
        .your-ticket-sec {
            .print-btn {
                width: 100%;
                display: inline-block;
                button {
                    &.cus-btn {
                        margin: 5px 0;
                        width: 100%;
                    }
                }
            }
        }
    }
    .db-main-container {
        .db-main-con-inner {
            .db-right-container {
                padding: 0 16px;
            }
            header {
                h6 {
                    font-size: 18px;
                }
            }
        }
    }
    .db-user-top-grids {
        .db-heading {
            h2 {
                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
        .das-sum {
            .das-sum-listing {
                .db-sum-card {
                    padding: 25px 15px;
                }
            }
        }
    }
    .db-heading {
        display: inline-block;
        &.res-d-inline-blook {
            .heading-btns-group {
                margin-top: 0;
            }
        }
        h2 {
            font-size: 24px;
        }
        button {
            &.cus-btn {
                min-width: 100%;
                margin-top: 15px;
            }
        }
        .heading-btns-group {
            display: inline-block;
            width: 100%;
            button {
                &.cus-btn {
                    min-width: 100%;
                    margin-top: 15px;
                }
            }
            .heading-select {
                width: 100%;
                display: inline-block;
                .MuiFormControl-root {
                    min-width: 100%;
                    margin: 8px 0;
                }
            }
        }
    }
    .db-sub-heading {
        display: inline-block;
        button {
            &.cus-btn {
                min-width: 100%;
                margin-top: 15px;
            }
        }
        .heading-btns-group {
            display: inline-block;
            width: 100%;
            button {
                &.cus-btn {
                    min-width: 100%;
                    margin-top: 15px;
                }
            }
            .heading-select {
                width: 100%;
                display: inline-block;
                .MuiFormControl-root {
                    min-width: 100%;
                    margin: 8px 0;
                }
            }
        }
    }
    .container-spacing-50 {
        padding: 20px;
    }
    .cus-upcoming-shows {
        .upcoming-shows-inner {
            .upcoming-shows-listing {
                .cus-card-inner {
                    .card-img-sec {
                        height: 200px;
                    }
                    .card-contents {
                        h2 {
                            white-space: normal;
                        }
                        p {
                            overflow: visible;
                            min-height: auto;
                            -webkit-line-clamp: inherit;
                            -webkit-box-orient: inherit;
                        }
                    }
                }
            }
        }
    }
    .db-add-events-page {
        .db-add-event-con {
            display: inline-block;
            .sidebar-add-event {
                margin: 0 auto;
                display: block;
            }
            .db-add-event-form {
                width: 100%;
                padding-left: 0;
                margin-top: 20px;
                .load-time-datepicker {
                    display: inline-block;
                    .datepicker-date {
                        margin: 4px 0;
                        width: 100%;
                        .MuiFormControl-root {
                            margin-right: 0;
                            width: 100%;
                        }
                    }
                }
                .event-field {
                    .MuiFormControl-root {
                        width: 100%;
                    }
                }
            }
            .cus-right-btn {
                button {
                    &.cus-btn {
                        min-width: 100%;
                    }
                }
            }
        }
    }
    .db-events-performance-page {
        .db-events-per-container {
            .db-events-performance-inner {
                ul {
                    li {
                        .datepicker-date {
                            max-width: 100%;
                        }
                        .field-wraper {
                            max-width: 100%;
                        }
                    }
                }
                .upload-img-sec {
                    width: 120px;
                    .upload-img-inner {
                        width: 120px;
                        height: 120px;
                        img {
                            width: 45px;
                            height: 45px;
                        }
                    }
                    h5 {
                        font-size: 12px;
                    }
                }
            }
        }
        .add-more-performance {
            button {
                &.cus-btn {
                    min-width: 250px;
                }
            }
        }
        .cus-right-btn {
            button {
                &.cus-btn {
                    min-width: 100%;
                    margin-top: 15px;
                    margin-left: 0;
                }
            }
        }
    }
    .db-events-prices-page {
        .event-card-prices {
            .cus-general-prices {
                ul {
                    li {
                        margin-right: 0;
                        max-width: 100%;
                        &:nth-child(even) {
                            margin-right: 0;
                            margin-left: 0;
                        }
                        .error {
                            position: static;
                        }
                    }
                }
            }
            .cus-extra-field {
                .cus-extra-field-inner {
                    max-width: 100%;
                    button {
                        &.cus-btn {
                            min-width: 100%;
                        }
                    }
                }
            }
            .cus-right-btn {
                button {
                    &.cus-btn {
                        min-width: 100%;
                        margin: 6px 0px;
                    }
                }
            }
        }
    }
    .db-create-venue-page {
        .venue-listing-ul {
            li {
                width: 100%;
            }
        }
    }
    .db-sub-heading {
        h3 {
            font-size: 16px;
        }
    }
    .db-create-promocodes {
        .cus-gift-vouchers {
            ul {
                li {
                    .gift-voucher-list {
                        .gift-voucher-con {
                            top: 25px;
                            .gift-voucher-bottom {
                                margin-top: 45px;
                                p {
                                    font-size: 12px;
                                    margin-top: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .db-promo-code {
        .promo-code-inner {
            ul {
                li {
                    .field-wrap {
                        max-width: 100%;
                    }
                }
            }
        }
        .create-promo-code-btn {
            button {
                &.cus-btn {
                    min-width: 100%;
                }
            }
        }
    }
    .db-manual-tickets-page {
        .promo-code-inner {
            ul {
                li {
                    .rep-filed {
                        .rep-adult {
                            .multi-icon {
                                padding: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .db-manual-tickets-detail {
        .manual-ticket-sec {
            .manual-ticket-img {
                img {
                    max-width: 300px;
                    width: 100%;
                }
            }
        }
    }
    .db-web-page {
        .web-page-inner {
            .web-page-container {
                display: inline-block;
                .event-img-con {
                    width: 120px;
                    margin: 0 auto;
                    display: block;
                }
                .web-page-right {
                    width: 100%;
                    padding-left: 0;
                    padding-top: 25px;
                }
            }
        }
        .dramatix-flyer-btn {
            button {
                &.cus-btn {
                    min-width: 180px;
                }
            }
        }
    }
    .custom-web-page {
        .web-page-logo {
            img {
                max-width: 150px;
            }
        }
    }
    .db-create-venue-seat {
        .dup-rem-btns {
            display: inline-block;
        }
        .dup-rem-btns,
        .update-seat-name-btn {
            button {
                &.cus-btn {
                    min-width: 100%;
                    margin: 5px 0;
                }
            }
        }
        .bottom-btns-group {
            display: inline-block;
            width: 100%;
            button {
                &.cus-btn {
                    min-width: 100%;
                    margin: 5px 0px;
                    &:last-child {
                        margin-left: 0;
                    }
                }
            }
        }
        .add-zone-btn {
            button {
                &.cus-btn {
                    min-width: 100%;
                    margin: 5px 0;
                }
            }
        }
        .field-rep {
            .MuiFormControl-root {
                margin: 0 0 5px 0;
            }
            .container-spacing-50 {
                padding: 20px 10px;
            }
        }
        .table-zone-sec {
            .field-rep {
                button {
                    &.cus-btn {
                        margin: 5px 0;
                        min-width: 100%;
                    }
                }
                .zone-main-sec {
                    .zone-input-sec {
                        .zone-btn-here {
                            .zone-btn-wrap {
                                flex-direction: column;
                            }
                        }
                    }
                }
            }
        }
        .del-icon {
            right: -16px;
            img {
                width: 14px;
                height: 14px;
            }
        }
    }
    .auth-wrapper {
        .cus-form {
            .cus-form-inner {
                padding: 20px 15px;
                .cus-form-width {
                    .forgot-password-sec {
                        a {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .db-sellers-page {
        .db-heading {
            .MuiFormControl-root {
                min-width: 100%;
            }
            button {
                &.cus-btn {
                    margin: 15px 0 0 0;
                }
            }
        }
    }
    .db-create-promocodes {
        .db-heading {
            &.res-d-inline-blook {
                .d-flex {
                    &.align-center {
                        display: inline-block;
                    }
                }
            }
            .MuiFormControl-root {
                min-width: 100%;
            }
            button {
                &.cus-btn {
                    min-width: 100%;
                    margin: 0;
                }
            }
            .cus-page-search {
                margin: 15px 0 0 0;
            }
        }
    }
    .ad-your-events {
        &.cus-promoter-events {
            .db-heading {
                button {
                    &.cus-btn {
                        margin: 0 0 15px 0;
                    }
                }
                .cus-page-search {
                    max-width: 100%;
                }
            }
            .d-flex {
                &.align-center {
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }
    .db-event {
        .db-heading {
            button {
                &.cus-btn {
                    min-width: 180px;
                }
            }
        }
    }
    .cus-sellers-events {
        .db-heading {
            padding: 0 0 20px 0;
        }
        .cus-tab-list {
            .tab-contents {
                .db-heading {
                    padding: 0 0 20px 0;
                }
                .tab-inner-head {
                    button {
                        &.cus-btn {
                            top: 60px;
                            right: 15px;
                        }
                    }
                }
            }
        }
        .cus-profile-fileds {
            .container-spacing-30 {
                padding: 20px;
            }
        }
    }
    .event-filter-dropdown {
        .MuiMenu-paper {
            top: 180px !important;
        }
    }
    .ad-override-event-fees {
        .bg-blue-color {
            min-height: auto;
        }
        .db-heading {
            padding: 0 0 20px 0;
        }
        .event-fees-fields {
            display: inline-block;
        }
        .event-fees-select {
            margin-right: 0;
            margin-bottom: 15px;
        }
        .event-fees-input {
            margin-bottom: 15px;
        }
    }
    .db-tickets-page {
        .tickets-fields {
            ul {
                li {
                    width: 50%;
                }
            }
        }
        .cus-page-search {
            margin: 15px 0 0 0;
        }
        .event-actions {
            display: inline-block;
            .cus-button-groups {
                flex-direction: column;
                margin-top: 10px;
                button {
                    &.cus-btn {
                        min-width: 100%;
                        margin: 6px 0;
                    }
                }
            }
        }
    }
    .profit-loss-form {
        padding: 30px 0;
    }
    .ad-report-page {
        .cus-bottom-price {
            ul {
                width: 320px;
                margin-top: 30px;
            }
        }
        .ad-report-table-head {
            flex-direction: column;
            .report-head-right {
                margin-top: 12px;
            }
        }
    }
    .expenditure-form {
        padding: 10px 0 20px 0;
        .MuiContainer-root {
            padding: 0;
        }
        ul {
            width: 100%;
            li {
                .rep-field-form {
                    .MuiFormControl-root {
                        min-width: 100%;
                    }
                }
            }
        }
        .changes-pass-icon {
            img {
                width: 60px;
                height: 60px;
            }
        }
    }
    .ad-expenditure-page {
        .db-heading {
            padding: 0 0 10px 0;
        }
    }
    .db-create-venue-page {
        .db-sub-heading {
            .MuiFormControl-root {
                margin: 15px 0 0 0;
                min-width: 100%;
            }
        }
    }
    .cus-seat-arrangement {
        .cus-items-zone {
            .cus-seats-items {
                .cus-seats-grid {
                    .cus-seat-plan {
                        .seat-inner {
                            width: 14px;
                            height: 14px;
                            margin: 2px;
                            svg {
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
    .ad-refund-page {
        ul {
            li {
                display: inline-block;
                p {
                    width: 100%;
                    font-size: 14px;
                    padding-right: 0;
                    padding-bottom: 5px;
                }
                h5 {
                    width: 100%;
                    font-size: 14px;
                }
            }
        }
    }
    .db-transactions-page {
        .date-picker-sec {
            flex-direction: column;
            width: 100%;
            .DateRangePickerInput__withBorder {
                width: 100%;
            }
            .DateRangePicker {
                padding-right: 0;
                margin-bottom: 15px;
                max-width: 100%;
            }
            a {
                width: 100%;
                display: inline-block;
                margin-top: 15px;
                button {
                    &.cus-btn {
                        width: 100%;
                    }
                }
            }
        }
    }
    .no-tickets-found {
        img {
            width: 120px;
            height: 120px;
        }
        h6 {
            font-size: 14px;
        }
    }
    .ad-edit-ticket-page {
        ul {
            li {
                .ticket-name-head {
                    label,
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .cus-table {
        .no-data-found {
            img {
                width: 80px;
                height: 80px;
            }
            h6 {
                font-size: 12px;
            }
        }
    }
    .cus-delete-modal {
        padding: 40px 0 30px 0;
    }
}
@media only screen and (max-width: 420px) {
    .cus-header {
        .header-container {
            .cus-logo {
                img {
                    width: 140px;
                }
            }
        }
    }
    .cus-main-slider {
        .slider-left-sec {
            .slider-list {
                .slider-contents {
                    h2 {
                        margin: 5px 0px;
                        font-size: 20px;
                    }
                }
            }
            .slick-dots {
                li {
                    width: 12px;
                    height: 12px;
                    button {
                        &::before {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
        .slider-right-sec {
            .cat-grids-sec {
                margin-top: 0;
                ul {
                    li {
                        padding-right: 4px;
                        padding-left: 4px;
                        margin-bottom: 8px;
                        .overlap-cards-con {
                            .overlap-cards-top-img {
                                height: 90px;
                                width: 110px;
                            }
                            .overlap-cards-bottom-img {
                                height: 160px;
                                .overlap-cards-content {
                                    padding: 15px 8px 15px 8px;
                                    .cat-tittle-show {
                                        width: calc(100% - 20px);
                                        h4 {
                                            font-size: 16px;
                                        }
                                        p {
                                            font-size: 12px;
                                        }
                                    }
                                    .cat-arrow {
                                        width: 20px;
                                        height: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-auth-page {
        .cus-auth-inner {
            .cus-auth-con {
                .otp-fields-sec {
                    ul {
                        li {
                            margin: 15px 4px;
                        }
                    }
                }
            }
        }
    }
    .cus-tabs {
        padding: 20px 0 30px;
    }
    .banner-ad-sec {
        .ads-left-con {
            background-position: 40px 50px;
            button {
                min-width: 150px;
                font-size: 12px;
                height: 42px;
            }
        }
    }
    .single-show-page {
        .artist-cards-inner {
            .cus-artist-cards {
                ul {
                    li {
                        width: calc(50% - 20px);
                    }
                }
            }
        }
    }
    .cus-back-arrow {
        img {
            width: 30px;
            height: 15px;
        }
    }
    .cus-banner {
        img {
            min-height: 280px;
        }
    }
    .cus-contact-page {
        .cus-contact-us {
            .contact-us-left-sec {
                .contact-us-left-inner {
                    padding: 20px;
                }
            }
            .contact-us-right-sec {
                .contact-us-right-inner {
                    .contact-us-social-icons {
                        ul {
                            margin: 10px 0 0 0;
                            li {
                                a {
                                    height: 35px;
                                    width: 35px;
                                    img {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }
                            }
                        }
                    }
                    ul {
                        li {
                            svg {
                                margin-right: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-booking-page {
        .cus-booking-accordion {
            .cus-accordion {
                .accordion-listing {
                    .accordion-details {
                        p {
                            font-size: 13px;
                        }
                    }
                }
            }
            .promotional-code-sec {
                ul {
                    li {
                        .promo-field {
                            button {
                                &.cus-btn {
                                    position: static;
                                    margin-top: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .cus-tab-list {
        header {
            button {
                margin-right: 40px;
            }
        }
        .tab-contents {
            .cus-booking-history {
                ul {
                    li {
                        padding: 12px;
                    }
                }
            }
            .wallet-list {
                ul {
                    li {
                        margin: 0 0 10px 0;
                        width: 100%;
                    }
                }
            }
        }
    }
    .cus-gift-vouchers {
        ul {
            li {
                .gift-voucher-list {
                    .gift-voucher-img {
                        img {
                            height: 120px;
                        }
                    }
                    .gift-voucher-con {
                        top: 15px;
                        .gift-voucher-bottom {
                            margin-top: 15px;
                            p {
                                font-size: 10px;
                            }
                            button {
                                &.cus-btn {
                                    min-width: 120px;
                                    margin-top: 4px;
                                    height: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .confirm-booking-modal {
        .your-ticket-sec {
            padding: 0;
        }
        > div {
            padding: 0;
        }
    }
    .db-main-container {
        .db-main-con-inner {
            header {
                h6 {
                    display: none;
                }
            }
        }
    }
    .db-top-bar {
        .promoter-header {
            ul {
                li {
                    .notification-sec {
                        width: 35px;
                        height: 35px;
                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                    .user-login {
                        .user-icon {
                            width: 35px;
                            height: 35px;
                            img {
                                width: 15px;
                                height: 15px;
                            }
                        }
                        span {
                            font-weight: 600;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .db-events-performance-page {
        .db-events-per-container {
            .db-events-performance-inner {
                ul {
                    li {
                        .datepicker-date {
                            display: inline-block;
                            .filed-time {
                                margin: 10px 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .db-events-prices-page {
        .event-card-prices {
            .cus-booking-fee {
                ul {
                    display: inline-block;
                    li {
                        max-width: 100%;
                        margin: 8px 0;
                    }
                }
                .cus-booking-fee {
                    margin: 0 0 25px 0;
                }
            }
            .cus-extra-field {
                .cus-extra-field-inner {
                    .delete-icon {
                        right: -32px;
                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }
    .db-create-venue-page {
        .venue-listing-sec {
            .db-heading {
                .heading-btns-group {
                    button {
                        &.cus-btn {
                            font-size: 13px;
                            padding: 6px 8px;
                        }
                    }
                }
                button {
                    &.cus-btn {
                        padding: 6px 2px;
                    }
                }
            }
        }
        .venue-listing-ul {
            li {
                .venue-listing-inner {
                    padding: 25px 15px;
                }
            }
        }
    }
    .db-create-promocodes {
        .cus-gift-vouchers {
            ul {
                li {
                    .gift-voucher-list {
                        .gift-voucher-con {
                            top: 15px;
                            p {
                                font-size: 14px;
                            }
                            .gift-voucher-bottom {
                                margin-top: 25px;
                                p {
                                    font-size: 10px;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .db-promo-code {
        .promo-code-inner {
            ul {
                li {
                    .field-wrap {
                        .datepicker-date {
                            display: inline-block;
                            .date-picker-material-ui {
                                margin-right: 0;
                                margin-bottom: 20px;
                                width: 100%;
                            }
                        }
                        .radio-field-wrap {
                            .MuiTextField-root {
                                width: 110px;
                            }
                        }
                    }
                }
            }
        }
    }
    .date-picker-sec {
        .DateRangePickerInput__withBorder {
            .DateRangePickerInput_arrow,
            .DateRangePickerInput_calendarIcon {
                margin: 0;
                width: 40px;
                text-align: center;
            }
        }
        .DateInput {
            width: calc(100% - 40px);
        }
    }
    .db-heading {
        h2 {
            font-size: 22px;
            .light-color-text {
                font-size: 16px;
                margin-left: 10px !important;
            }
        }
    }
    .profit-loss-form {
        padding: 20px 0;
        .MuiContainer-root {
            padding: 0;
        }
        .datepicker-date {
            display: inline-block;
            .date-picker-material-ui {
                margin: 6px 0 !important;
                width: 100%;
            }
        }
    }
    .ad-report-page {
        .cus-bottom-price {
            ul {
                width: 240px;
                margin-top: 20px;
                li {
                    h2,
                    h3 {
                        font-size: 18px;
                    }
                }
            }
        }
        .ad-report-table-head {
            .report-head-right {
                flex-direction: column;
                margin-top: 5px;
                button {
                    &.cus-btn {
                        margin: 8px 0 !important;
                    }
                }
            }
        }
    }
    .db-transactions-page {
        .date-picker-sec {
            .DateRangePickerInput__withBorder {
                .DateInput.DateInput_1 {
                    width: calc(100% - 40px);
                }
            }
        }
    }
    .cus-show-filters {
        .shows-filters-container {
            .accordion-details {
                padding: 0 0 16px;
            }
        }
    }
    .__app_container__ {
        .__global_loader__ {
            div {
                div {
                    width: 60px !important;
                }
            }
        }
    }
    .db-create-venue-seat {
        .table-zone-sec {
            .field-rep {
                .table-grid-main {
                    .table-rep-grid {
                        .d-flex {
                            display: inline-block;
                        }
                        .field-rep {
                            margin: 5px 0;
                            &:last-child {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .db-tickets-page {
        .tickets-fields {
            ul {
                li {
                    width: 100%;
                }
            }
        }
    }
    .modal-notification {
        .MuiPaper-root {
            top: 62px !important;
            .notification-dropdown {
                width: 280px;
            }
        }
    }
    .custom-chart-main {
        .custom-chart-heading {
            .chart-head-right {
                > div {
                    width: 100%;
                }
            }
        }
    }
    .no-data-found {
        .no-data-found-img {
            width: 120px;
            height: 120px;
        }
        h6 {
            font-size: 14px;
        }
    }
    .cus-promoter-events-add {
        .db-events-prices-page {
            .event-card-prices {
                .cus-booking-fee {
                    ul {
                        li {
                            max-width: 100%;
                            margin: 2px 0 16px;
                            &:last-child {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
