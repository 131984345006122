.d-block {
    display: block !important;
}
.d-none {
    display: none !important;
}
.d-flex {
    display: flex;
}
.bold {
    font-weight: bold;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.cus-pointer {
    cursor: pointer;
}
.align-center {
    align-items: center;
}
.con-space-between {
    justify-content: space-between;
}
.con-space-around {
    justify-content: space-around;
}
.con-flex-end {
    justify-content: flex-end;
}
.flex-dir-column {
    flex-direction: column;
}
.con-center {
    justify-content: center;
}
.con-left {
    justify-content: flex-start;
}
.flex-wrap {
    flex-wrap: wrap;
}
.box-shadow {
    @include box-shadow;
}
.box-shadow-none {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.border-radius-none {
    border-radius: 0 !important;
}
.border-none {
    border: none !important;
}
.white-space {
    white-space: nowrap;
}
.w-100 {
    width: 100%;
}
.w-50 {
    width: 50%;
}
.w-33 {
    width: 33.33%;
}
.w-25 {
    width: 25%;
}
.w-20 {
    width: 20%;
}
.error {
    margin: 0;
    color: $red-color !important;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}
.disabled {
    opacity: 0.6;
    pointer-events: none;
}
.p-0 {
    padding: 0 !important;
}
.p-1 {
    padding: 12px !important;
}
.p-2 {
    padding: 24px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-30 {
    margin-top: 30px !important;
}
.mt-40 {
    margin-top: 40px !important;
}
.mt-50 {
    margin-top: 50px !important;
}
.mb-0 {
    margin-bottom: 0px !important;
}
.mb-5 {
    margin-bottom: 5px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-15 {
    margin-bottom: 15px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.mb-40 {
    margin-bottom: 40px !important;
}
.mb-50 {
    margin-bottom: 50px !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.mr-5 {
    margin-right: 5px !important;
}
.ml-5 {
    margin-left: 5px !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.ml-20 {
    margin-left: 20px !important;
}
.p-5 {
    padding: 10px !important;
}
.p-24 {
    padding: 24px !important;
}
.pt-0 {
    padding-top: 0 !important;
}
.pt-5 {
    padding-top: 20px !important;
}
.pb-5 {
    padding-bottom: 20px !important;
}
.pr-3 {
    padding-right: 5px !important;
}
.po-relative {
    position: relative;
}
.bg-white-color {
    background-color: $white-color;
}
.bg-blue-color {
    background-color: $light-blue-color;
}
.border-radius-20 {
    border-radius: 20px;
}
.container-spacing {
    padding: 60px;
}
.container-spacing-50 {
    padding: 50px;
}
.container-spacing-30 {
    padding: 30px;
}
/* Loading Design Css Start */
.__app_container__ {
    position: relative;
    .__global_loader__ {
        &.showing {
            display: none;
        }
        position: fixed;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.4);
        z-index: 99999;
        left: 0;
        div {
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            img {
                height: 100%;
            }
        }
    }
}
.loading-wrapper-custom {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(230, 230, 230, 0.4);
    z-index: 99999;
    left: 0;
    .loader {
        width: 98px;
        img {
            width: 100%;
            object-fit: contain;
        }
    }
}
/* Loading Design Css Ends */

/* Button Design Css Start */
.cus-btn {
    border-radius: 8px;
    height: 48px;
    font-size: 15px;
    font-weight: 600;
    box-shadow: none;
    min-width: 150px;
    padding: 6px 15px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    background-color: $dark-grey-color;
    color: $white-color;
    .circular-progress {
        color: $white-color;
    }
    .plus-icon {
        margin-right: 10px;
        font-size: 24px;
    }
    &:hover,
    &:focus {
        background-color: $black-color;
        color: $white-color;
        border: none;
        outline: none;
    }
    &.white-btn {
        background-color: $white-color;
        color: $dark-grey-color;
        &:hover,
        &:focus {
            background-color: $light-white-color;
            color: $dark-grey-color;
        }
    }
    &.blue-btn {
        background-color: $blue-color;
        color: $white-color;
        &:hover,
        &:focus {
            background-color: $dark-blue-color;
            color: $white-color;
        }
    }
    &.grey-btn {
        background-color: $grey-normal-color;
        color: $dark-grey-color;
        &:hover,
        &:focus {
            background-color: $light-white-color;
            color: $dark-grey-color;
        }
    }
    &.green-btn {
        background-color: $green-color;
        color: $white-color;
        &:hover,
        &:focus {
            background-color: $light-green-color;
            color: $white-color;
        }
    }
    &.transparent-btn {
        background-color: transparent;
        color: $dark-grey-color;
        &:hover,
        &:focus {
            background-color: transparent;
            color: $dark-grey-color;
        }
    }
    &.red-btn {
        background-color: $red-btn-color;
        color: $white-color;
        border-color: $red-btn-color;
        &:hover,
        &:focus {
            background-color: $red-hover-btn-color;
            color: $white-color;
            border-color: $red-btn-color;
        }
    }
    &.orange-btn {
        background-color: $orange-color;
        border-color: $orange-color;
        color: $white-color;
        &:hover,
        &:focus {
            background-color: $orange-hover-color;
            border-color: $orange-hover-color;
            color: $white-color;
        }
    }
    &.black-btn {
        &.Mui-disabled {
            color: $grey-color;
        }
    }
}
button {
    &.cus-btn {
        border-radius: 8px;
        height: 48px;
        font-size: 15px;
        font-weight: 600;
        box-shadow: none;
        min-width: 150px;
        padding: 6px 15px;
        text-transform: uppercase;
        border: none;
        cursor: pointer;
        background-color: $dark-grey-color;
        color: $white-color;
        &:hover,
        &:focus {
            background-color: $black-color;
            border: none;
            outline: none;
        }
        &.white-btn {
            background-color: $white-color;
            color: $dark-grey-color;
            &:hover,
            &:focus {
                background-color: $light-white-color;
                color: $dark-grey-color;
            }
        }
        &.red-btn {
            background-color: $red-btn-color;
            border-color: $red-btn-color;
            color: $white-color;
            &:hover,
            &:focus {
                background-color: $red-hover-btn-color;
                border-color: $red-btn-color;
                color: $white-color;
            }
        }
        &.orange-btn {
            background-color: $orange-color;
            border-color: $orange-color;
            color: $white-color;
            &:hover,
            &:focus {
                background-color: $orange-hover-color;
                border-color: $orange-hover-color;
                color: $white-color;
            }
        }
    }
    &.blue-btn {
        background-color: $blue-color;
        color: $white-color;
        &:hover,
        &:focus {
            background-color: $dark-blue-color;
            color: $white-color;
        }
    }
    &.grey-btn {
        background-color: $grey-normal-color;
        color: $dark-grey-color;
        &:hover,
        &:focus {
            background-color: $grey-normal-color;
            color: $dark-grey-color;
        }
    }
}
button {
    &:hover,
    &:focus {
        outline: none;
    }
}
/* Button Design Css Ends */

/* Section Heading/Sub Heading Css Start */
.cus-sec-heading {
    padding: 30px 0;
    h2 {
        font-weight: bold;
        font-size: 34px;
        color: $dark-grey-color;
    }
}
.cus-modal {
    .cus-sec-heading {
        h2 {
            font-size: 28px;
        }
    }
}
.cus-sec-sub-heading {
    p {
        font-weight: normal;
        font-size: 16px;
        color: $dark-grey-color;
    }
}
.db-heading {
    padding: 30px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
        font-weight: bold;
        font-size: 30px;
        color: $dark-grey-color;
        .light-color-text {
            color: $light-white-color;
            font-family: "Montserrat", sans-serif !important;
        }
    }
    button {
        &.cus-btn {
            height: 40px;
            font-size: 14px;
            min-width: 180px;
        }
    }
    .heading-btns-group {
        .heading-select {
            .MuiFormControl-root {
                background-color: $white-color;
            }
        }
    }
}
.db-sub-heading {
    padding: 30px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        font-weight: normal;
        font-size: 16px;
        color: $dark-grey-color;
    }
    h3 {
        font-weight: bold;
        font-size: 22px;
        color: $dark-grey-color;
    }
    button {
        &.cus-btn {
            height: 40px;
            font-size: 14px;
            min-width: 180px;
        }
    }
}
.db-section-heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h4 {
        font-weight: bold;
        font-size: 20px;
        color: $dark-grey-color;
    }
}
.cus-center-heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0px;
    position: relative;
    h5 {
        font-weight: 600;
        font-size: 16px;
        color: $dark-grey-color;
        background-color: $white-color;
        padding: 0 15px;
        text-transform: capitalize;
        position: relative;
    }
    &::before {
        content: "";
        border-top: 1px solid $table-border-color;
        width: 100%;
        height: 1px;
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
    }
}
/* Section Heading/Sub Heading Css Ends */

/* Back Arrow Css Start */
.cus-back-arrow {
    width: 100%;
    display: inline-block;
    img {
        width: 50px;
        height: 25px;
        cursor: pointer;
    }
}
.db-back-arrow-sec {
    margin: 20px 0;
}
/* Back Arrow Css Ends */

/* Pagination Css Start */
.cus-pagination {
    display: flex;
    justify-content: center;
}
/* Pagination Css Ends */

/* No Tickets Found Css Start */
.no-tickets-found {
    padding: 30px 0;
    img {
        width: 150px;
        height: 150px;
    }
    h6 {
        font-weight: 500;
        font-size: 16px;
        color: $dark-grey-color;
    }
}
/* No Tickets Found Css Ends */

/* No Data Found Css Start */
.no-data-found {
    .no-data-found-img {
        width: 150px;
        height: 150px;
    }
    h6 {
        font-size: 16px;
        color: $dark-grey-color;
        padding: 0;
    }
}
/* No Data Found Css Ends */
