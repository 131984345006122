/* Colors Here */
$dark-grey-color: #292929;
$grey-color: #606060;
$grey-normal-color: #F3F3F3;
$light-grey-normal-color: #949494;
$light-grey-color: #fafafa;
$light-white-color:#e2e2e2;
$blue-color: #5EB7DF;
$blue-org-color: #477CAC;
$dark-blue-color: #3cacde;
$blue-normal-color: #8dddff;
$light-blue-color: #F2FAFD;
$tabs-light-blue: #e4f3fa;
$table-border-color: #C6DAED;
$white-color: #ffffff;
$black-color: #000000;
$yellow-color: #FFF03B;
$light-yellow-color: #FFCC77;
$dark-yellow-color: #CCC024;
$red-color: #ff0000;
$green-color: #1DB023;
$light-green-color: #19961e;
$red-btn-color: #f20712;
$red-hover-btn-color: #d00d17;
$orange-color: #ffa500;
$orange-hover-color: #d88c01;
$bg-light-color: #f5fafd;
$base-color: #4d5567;

/* Font Family Here */
.font-open-sans{
    font-family: 'Open Sans', sans-serif !important;
}
.font-montserrat{
    font-family: 'Montserrat', sans-serif !important;
}
@mixin font-open-sans {
    font-family: 'Open Sans', sans-serif !important;
}
@mixin font-montserrat {
    font-family: 'Montserrat', sans-serif !important;
}

/* Box Shadow Here */
@mixin box-shadow {
    -webkit-box-shadow: 0px 0px 20px #00000012;
    -moz-box-shadow: 0px 0px 20px #00000012;
    box-shadow: 0px 0px 20px #00000012;
}

/* Hover Transition Css Start */
@mixin cus-transition {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;
}
@mixin cus-hover-transition {
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;
}
/* Hover Transition Css Ends */
