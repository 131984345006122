//Events
.cus-upcoming-shows {
    .upcoming-shows-inner {
        .upcoming-shows-listing {
            .cus-card-inner {
                .view-name {
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    z-index: 7;
                    display: flex;
                    background-color: #ffffff;
                    border-radius: 7px;
                    padding: 6px 8px;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
